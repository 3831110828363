import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Select} from "antd";
import {FlattenOptionData} from "rc-select/lib/interface";
import {BaseOptionType} from "rc-select/lib/Select";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {YearsTag} from "./filters/YearsTag";
import {PositionTag} from "./filters/PositionTag";
import {CloseOutlined} from "@ant-design/icons";
import './FiltersComponent.scss'
import {RcrTag} from "./filters/RcrTag";
import {SettingsContext} from "./Navigation";
import {NpTag} from "./filters/NpTag";
import {CitationsTag} from "./filters/CitationsTag";
import {RankTag} from "./filters/RankTag";
import {TypeTag} from "./filters/TypeTag";
import {FiltersDto} from "../generated/api";

export function constructFilters(val: string[]) {
    let ret: FiltersDto = {}
    val.forEach(x=>{
        if (x.includes(':')) {
            const value = x.split(':', 2)[1].trim();
            if (x.startsWith('year:')) {
                const academic = value.split(",")
                const yearArray = academic[0].split('-')
                ret.startYear = Number(yearArray[0])
                if (yearArray.length > 1) ret.endYear = Number(yearArray[1])
                if (academic.length > 1) ret.useAcademicYear = true
            } else if (x.startsWith('position:')) {
                ret.position = value.split(',').filter(z=>z === '1st' || z === '2nd' || z === 'last')
            } else if (x.startsWith('rcr:')) {
                ret.minRcr = Number(value)
            } else if (x.startsWith('citations:')) {
                ret.minCitations = Number(value)
            } else if (x.startsWith('np:')) {
                ret.minNp = Number(value)
            } else if (x.startsWith('title:')) {
                if (ret.paper===undefined) ret.paper=[]
                ret.paper.push(value)
            } else if (x.startsWith('journal:')) {
                if (ret.journal===undefined) ret.journal=[]
                ret.journal.push(value)
            } else if (x.startsWith('rank:')) {
                ret.rank = value.split(',').filter(z=>z!=="")
            } else if (x.startsWith('type:')) {
                ret.types = value.split(',').filter(z=>z!=="")
            } else if (x.startsWith('research:')) {
                ret.researchArticle = value==='1'
            } else if (x.startsWith('hypothesis:')) {
                ret.hypothesis = value==='1'
            } else if (x.startsWith('division:')) {
                if (ret.division===undefined) ret.division=[]
                ret.division.push(value)
            }
        }
    })
    return ret
}

interface FiltersSelectProps {
    setFilters: (value: string[]) => void
    filters: string[]
    singlePersonMode?: boolean
}

export interface FiltersProps {
    setFilters: (value: string[]) => void
    filters: string[]
    layout: string
    setLayout: (value: string) => void
}

export interface FiltersUpdateContextInterface {
    addFilter?: (val: string[])=>void
    removeFilter?: (val: string)=>void
}

export const FiltersUpdateContext=React.createContext<FiltersUpdateContextInterface>({})

export function FiltersSelect(props: Readonly<FiltersSelectProps>) {
    const [isOpen, setIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [yearsOpen, setYearsOpen] = useState(false)
    const [rcrOpen, setRcrOpen] = useState(false)
    const [npOpen, setNpOpen] = useState(false)
    const [citationsOpen, setCitationsOpen] = useState(false)
    const [rankOpen, setRankOpen] = useState(false)
    const [typeOpen, setTypeOpen] = useState(false)

    const multiValues = [{
        value: 'title',
        labelContains: 'Paper title contains',
        label: 'Paper title'
    }, {
        value: 'journal',
        labelContains: 'Journal title contains',
        label: 'Journal'
    }, {
        value: 'division',
        labelContains: 'Division name contains',
        label: 'Division'
    }]

    const singletonValues = ['year', 'rcr', 'np', 'citations', 'position', 'rank', 'type']

    function refreshOptions(searchValue?: string, addedVal?: string, removedVal?: string) {
        if (!searchValue) {
            const has = Object.fromEntries(singletonValues.map(z=>
                [z, addedVal===z || (props.filters.some(x=>x.startsWith(z)) && !removedVal?.startsWith(z))]
            ))
            setOptions(defaultOptions.filter(x=>{
                return !has[x.value];

            }))
        } else {
            let anyMatch = false;
            for (let x in multiValues) {
                if (searchValue.startsWith(multiValues[x].value+":")) {
                    const param = searchValue.split(":", 2)
                    let par = param[1].trim()
                    if (par.startsWith("~")) par = par.substring(1)
                    anyMatch = true
                    setOptions([{
                        label: <>{multiValues[x].labelContains}: <b>{par}</b></>,
                        value: multiValues[x].value + ": ~" + par
                    }])
                    break
                }
            }
            if (!anyMatch) {
                setOptions(multiValues.map(x=>({
                    label: <>{x.labelContains}: <b>{searchValue}</b></>,
                    value: x.value+": ~" + searchValue
                })))
            }
        }
    }

    useEffect(()=>{
        refreshOptions('')
    }, [props.filters])

    function onSearch(value: string) {
        setSearchValue(value)
        refreshOptions(value)
    }

    function onSelect(val: string) {
        if (val==='year' || val==='rcr' || val==='np' || val==='citations' || val==='research' || val==='hypothesis') {
            setIsOpen(false)
            refreshOptions(undefined, val)
        }
        if (val==='position') {
            refreshOptions(undefined, val)
        }
        if (val==='year') {
            setYearsOpen(true)
        }
        if (val==='rcr') {
            setRcrOpen(true)
        }
        if (val==='citations') {
            setCitationsOpen(true)
        }
        if (val==='rank') {
            setIsOpen(false)
            setRankOpen(true)
        }
        if (val==='type') {
            setIsOpen(false)
            setTypeOpen(true)
        }
        if (val==='np') {
            setNpOpen(true)
        }
    }

    function onDeselect(val: string) {
        refreshOptions(undefined, undefined, val)
    }

    const defaultOptions = [{
        label: 'Filter by year',
        value: 'year'
    }, {
        label: 'Filter by position',
        value: 'position'
    }, {
        label: 'Filter by paper title',
        value: 'title'
    }, ...props.singlePersonMode?[]:[{
        label: 'Filter by division',
        value: 'division'
    }], {
        label: 'Filter by journal',
        value: 'journal'
    }, {
        label: 'Filter by RCR (min value)',
        value: 'rcr'
    }, {
        label: 'Filter by NIH Percentile (min value)',
        value: 'np'
    }, {
        label: 'Filter by Citations (min value)',
        value: 'citations'
    }, ...props.singlePersonMode?[]:[{
        label: 'Filter by Academic Rank',
        value: 'rank'
    }], {
        label: 'Filter by Publication Type',
        value: 'type'
    }, {
        label: 'Show Research Papers Only',
        value: 'research:1'
    }, {
        label: 'Show Hypothesis Papers Only',
        value: 'hypothesis:1'
    }]
    const [options, setOptions] = useState<BaseOptionType[]>(defaultOptions)

    function onChange(val: string[]) {
        let match = false;
        if (val.length) {
            const found = multiValues.find(x=>val[val.length-1] === x.value)
            if (found) {
                setSearchValue(found.value + ": ")
                refreshOptions(found.value +": ")
                match = true
            }
        }
        if (!match) {
            setSearchValue('')
            refreshOptions('')
            setValueInt(val)
        }
        return false;
    }

    function optionRender(oriOption: FlattenOptionData<BaseOptionType>) {
        return <div>{oriOption.label}</div>
    }

    function setValueInt(f: string[]) {
        props.setFilters(f)
    }

    function tagRender(tagProps: CustomTagProps) {
        if (tagProps.value==='year' || tagProps.value.startsWith('year:')) {
            return <YearsTag value={tagProps.value}
                             setValue={(value)=>{
                                 setValueInt(props.filters.map(x=>{
                                     if (x==='year' || x.startsWith("year:")) return 'year:'+value
                                     return x
                                 }))
                             }}
                             closeSelectDropdown={()=>setIsOpen(false)}
                             onRemove={tagProps.onClose}
                             yearsOpen={yearsOpen}
                             setYearsOpen={setYearsOpen}
                             showAcademic={true}
            />
        } else
        if (tagProps.value==='position' || tagProps.value.startsWith('position:')) {
            return <PositionTag value={tagProps.value}
                                setValue={(value)=>{
                                    setValueInt(props.filters.map(x=>{
                                        if (x==='position' || x.startsWith("position:")) return 'position:'+value
                                        return x
                                    }))
                                }}
                                onRemove={tagProps.onClose}/>
        } else
        if (tagProps.value==='rank' || tagProps.value.startsWith('rank:')) {
            return <RankTag value={tagProps.value}
                            setValue={(value)=>{
                                setValueInt(props.filters.map(x=>{
                                    if (x==='rank' || x.startsWith("rank:")) return 'rank:'+value
                                    return x
                                }))
                            }}
                            onRemove={tagProps.onClose}
                            rankOpen={rankOpen}
                            setRankOpen={setRankOpen}
                            closeSelectDropdown={()=>setIsOpen(false)}
            />
        } else
        if (tagProps.value==='type' || tagProps.value.startsWith('type:')) {
            return <TypeTag value={tagProps.value}
                            setValue={(value)=>{
                                setValueInt(props.filters.map(x=>{
                                    if (x==='type' || x.startsWith("type:")) return 'type:'+value
                                    return x
                                }))
                            }}
                            onRemove={tagProps.onClose}
                            typeOpen={typeOpen}
                            setTypeOpen={setTypeOpen}
                            closeSelectDropdown={()=>setIsOpen(false)}
            />
        } else
        if (tagProps.value==='rcr' || tagProps.value.startsWith('rcr:')) {
            return <RcrTag value={tagProps.value}
                           setValue={(value)=>{
                               setValueInt(props.filters.map(x=>{
                                   if (x==='rcr' || x.startsWith("rcr:")) return 'rcr:'+value
                                   return x
                               }))
                           }}
                           onRemove={tagProps.onClose}
                           rcrOpen={rcrOpen}
                           setRcrOpen={setRcrOpen}
                           closeSelectDropdown={()=>setIsOpen(false)}
            />
        } else if (tagProps.value==='np' || tagProps.value.startsWith('np:')) {
            return <NpTag value={tagProps.value}
                          setValue={(value)=>{
                              setValueInt(props.filters.map(x=>{
                                  if (x==='np' || x.startsWith("np:")) return 'np:'+value
                                  return x
                              }))
                          }}
                          onRemove={tagProps.onClose}
                          npOpen={npOpen}
                          setNpOpen={setNpOpen}
                          closeSelectDropdown={()=>setIsOpen(false)}
            />
        } else if (tagProps.value==='citations' || tagProps.value.startsWith('citations:')) {
            return <CitationsTag value={tagProps.value}
                                 setValue={(value)=>{
                                     setValueInt(props.filters.map(x=>{
                                         if (x==='citations' || x.startsWith("citations:")) return 'citations:'+value
                                         return x
                                     }))
                                 }}
                                 onRemove={tagProps.onClose}
                                 citationsOpen={citationsOpen}
                                 setCitationsOpen={setCitationsOpen}
                                 closeSelectDropdown={()=>setIsOpen(false)}
            />
        } else if (tagProps.value.startsWith('division:') && tagProps.value.includes('|')) {
            return <div className={'tag'}
                        style={{paddingLeft: 8,
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center'}}
            >division: {tagProps.value.split('|')[1]}
                <Button type={'text'} size={"small"} className={'close-tag'} onClick={tagProps.onClose}><CloseOutlined/></Button>
            </div>
        }
        return <div className={'tag'}
                    style={{paddingLeft: 8,
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center'}}
        >{tagProps.label}
            <Button type={'text'} size={"small"} className={'close-tag'} onClick={tagProps.onClose}><CloseOutlined/></Button>
        </div>
    }

    function dropdownRender(menu: React.ReactElement) {
        return <div>{menu}</div>
    }

    return <Select
            className={'filters'}
            open={isOpen}
            onDropdownVisibleChange={(open)=>{
                setIsOpen(open)
            }}
            style={{flex: 1}}
            value={props.filters}
            onSelect={onSelect}
            onDeselect={onDeselect}
            mode={'multiple'}
            onSearch={onSearch}
            searchValue={searchValue}
            placeholder={'Click to add filters'}
            onChange={onChange}
            options={options}
            listHeight={32*12}
            filterOption={false}
            optionRender={optionRender}
            tagRender={tagRender}
            dropdownRender={dropdownRender}
            suffixIcon={<Button type={'link'} onClick={()=>setValueInt([])}>
                Reset
            </Button>}
        />
}

export function FiltersComponent(props: FiltersProps) {
    return <SettingsContext.Consumer>
        {settings => <div style={{display: "flex", gap: 10, alignItems: "center", paddingBottom: 15}}>
            <span>Layout</span>
            <Select
                style={{width: 145}}
                options={[
                    {label: 'Default', value: 'default'},
                    ...(settings?.citationsFeature?[{label: 'Citation Metrics', value: 'metrics'}]:[]),
                    {label: 'Journals', value: 'journals'},
                    {label: 'By Years', value: 'years'}
                ]}
                value={props.layout}
                onChange={props.setLayout}
            />
            <span>Filters</span>
            <FiltersSelect filters={props.filters} setFilters={props.setFilters} />
        </div>}
    </SettingsContext.Consumer>
}