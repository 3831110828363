import {Badge, Button, Flex, Modal, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {FiltersDto, PersonNameDtoWithId, PublicationDto} from "../generated/api";
import {formatName} from "../utils/utils";
import {Bar, BarChart, LabelList, XAxis} from "recharts";
import {checkPosition, FilterAndSort} from "./FilterAndSort";
import {Props} from "recharts/types/component/Label";
import {RemovedPopup} from "./RemovedPopup";
import {useRolesProvider} from "../utils/keycloakUtils";
import {stopPropagationUtil} from "../utils/clicks";
import {AddMissingMultiplePopup} from "./AddMissingMultiplePopup";
import {PublicationsList} from "./PublicationsList";
import {ExportButton} from "./ExportButton";
import {constructFilters, FiltersSelect} from "./FiltersComponent";

interface PublicationsPopupProps {
    person?: PersonNameDtoWithId
    onClose: (shouldRefresh: boolean)=>void
    filters?: FiltersDto
}

function ChartLabel(p: Props) {
    return <text x={Number(p.x ?? 0) + Number(p.width ?? 0) / 2} y={Number(p.y ?? 0) - 5}
                 textAnchor="middle">{p.value ? p.value : ""}</text>
}

function reconstructFilters(filters: FiltersDto | undefined): string[] {
    if (!filters) return []
    const ret: string[] = []
    if (filters.startYear) {
        if (filters.endYear) {
            ret.push('year:' + filters.startYear + "-" + filters.endYear)
        } else {
            ret.push('year:' + filters.startYear)
        }
        //TODO: academic
    }
    if (filters.position) {
        ret.push('position:' + filters.position.join(","))
    }
    if (filters.minRcr) {
        ret.push('rcr:' + filters.minRcr)
    }
    if (filters.minCitations) {
        ret.push('citations:' + filters.minRcr)
    }
    if (filters.minNp) {
        ret.push('np:' + filters.minRcr)
    }
    if (filters.paper) {
        ret.push(...filters.paper.map(z=>'title:' + z))
    }
    if (filters.journal) {
        ret.push(...filters.journal.map(z=>'journal:' + z))
    }
    if (filters.types) {
        ret.push("type:" + filters.types.join(","))
    }
    if (filters.researchArticle) {
        ret.push("research:1")
    }
    if (filters.hypothesis) {
        ret.push("hypothesis:1")
    }
    return ret
}

export function PublicationsPopup(props: PublicationsPopupProps) {
    const api = useApi()

    const [data, setData] = useState<PublicationDto[]>()
    const [sortPublications, setSortPublications] = useState("pmid")
    const rolesProvider = useRolesProvider();
    const [removedPopupOpen, setRemovedPopupOpen] = useState(false)
    const [addMissingPopupOpen, setAddMissingPopupOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [minMax, setMinMax] = useState<number[]>()
    const [removedCount, setRemovedCount] = useState<number>()
    const [shouldRefresh, setShouldRefresh] = useState(false)

    const [filters, setFilters] = useState<string[]>([])
    const [filtersObj, setFiltersObj] = useState<FiltersDto>({})

    function loadPagedData(personId: number, page: number) {
        setLoading(true)
        if (page===0) {
            setData([])
        }
        api.listPublications(personId, page, 200).then(response => {
            if (response.data.length) {
                setData(oldValue=>{
                    if (oldValue===undefined) return response.data
                    return [...oldValue, ...response.data]
                })
                if (response.data.length<200) {
                    setLoading(false)
                } else {
                    loadPagedData(personId, page+1)
                }
            } else {
                setLoading(false)
            }
        })
    }

    function downloadData() {
        if (props.person) {
            const personId = props.person.id;
            api.getMinMaxYears(personId).then(response=> {
                setMinMax(response.data)
                loadPagedData(personId, 0)
            })
            api.getRemovedCount(personId, false).then(response=>{
                setRemovedCount(response.data)
            })
        }
    }

    useEffect(()=>{
        if (props.person) {
            setData(undefined)
            setFilters(reconstructFilters(props.filters))
            setFiltersObj(props.filters??{})
            downloadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.person, props.filters])

    function formatTitle() {
        return props.person?formatName(props.person):""
    }

    function renderBar(barProps: any) {
        barProps.className+=" year-chart-bar"
        if (filtersObj.startYear) {
            if (filtersObj.endYear) {
                if (Number(barProps.year)>=filtersObj.startYear && Number(barProps.year)<=filtersObj.endYear) {
                    barProps.className+=" selected"
                }
            } else {
                if (Number(barProps.year)===filtersObj.startYear) {
                    barProps.className+=" selected"
                }
            }
        }
        return Bar.renderRectangle(true, barProps);
    }

    function getYearsData(data: PublicationDto[]) {
        if (minMax) {
            const initial: { [key: number]: number } = {}
            for (let i = minMax[0]; i <= minMax[1]; i++) {
                initial[i] = 0;
            }
            return Object.entries(data?.reduce(function (rv, x) {
                if (checkPosition(filtersObj.position, x.position, x.authors)) {
                    rv[x.year] = (rv[x.year] ?? 0) + 1;
                }
                return rv;
            }, initial) ?? {}).map(x => ({year: x[0], count: x[1]}))
        }
    }

    function closeRemovedPopup(shouldRedownload: boolean) {
        setRemovedPopupOpen(false)
        if (shouldRedownload) {
            setShouldRefresh(true);
            downloadData()
        }
    }

    function closeAddMissingPopup(shouldRedownload: boolean) {
        setAddMissingPopupOpen(false)
        if (shouldRedownload) {
            setShouldRefresh(true);
            downloadData()
        }
    }

    function updateFilters(filter: string, value?: string) {
        setFilters(old=>{
            if (old.filter(x=>x.startsWith(filter + ':'))) {
                if (!value) {
                    return old.filter(x=>!x.startsWith(filter + ':'))
                } else {
                    return old.map(x=>{
                        if (x.startsWith(filter + ":")) {
                            return filter + ':' + value
                        } else {
                            return x;
                        }
                    })
                }
            } else {
                if (value) return [...old, filter + ':' + value]
            }
            return old;
        })
    }

    return <Modal open={!!props.person}
                  centered={true}
                  onCancel={()=>props.onClose(shouldRefresh)}
                  title={<Flex vertical gap={10}>
                              <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                                    {formatTitle()}
                                    <FilterAndSort sortPublications={sortPublications} setSortPublications={setSortPublications} />
                                    <ExportButton person={props.person} filters={filtersObj}/>
                                    <div style={{flex: 1}}/>
                              {rolesProvider.isManager() &&
                                  <Button size={"small"} type={'dashed'} onClick={()=>setAddMissingPopupOpen(true)} disabled={isLoading}>Import / Add Missing</Button>
                              }
                              {rolesProvider.isAdmin() && (removedCount??0)>0 &&
                                  <Badge count={removedCount} size={"small"}>
                                      <Button size={"small"} type={'dashed'} onClick={()=>setRemovedPopupOpen(true)}>Removed</Button>
                                  </Badge>
                              }
                              <div style={{width: 20}}/>
                          </div>
                        <FiltersSelect setFilters={x=>{
                            setFilters(x)
                            setFiltersObj(constructFilters(x))
                        }} filters={filters} singlePersonMode={true}/>
                    </Flex>}
                  width={940}
                  footer={<Button type="primary" onClick={()=>props.onClose(shouldRefresh)}>
                              Close
                          </Button>}>
        <RemovedPopup open={removedPopupOpen}
                      onClose={closeRemovedPopup}
                      person={props.person}
        />
        <AddMissingMultiplePopup open={addMissingPopupOpen}
                                 allPublications={data}
                                 onClose={closeAddMissingPopup}
                                 person={props.person}
        />
        <Spin spinning={isLoading}>
            <div style={{overflowY: "auto", height: 600}}>
                {data!==undefined && props.person &&
                    <>
                    <BarChart width={650} height={120} data={getYearsData(data)}
                              onClick={()=>{
                                  setFiltersObj(old=>({
                                      ...old,
                                      startYear: undefined,
                                      endYear: undefined
                                  }))
                                  updateFilters('year', undefined)
                              }}
                              margin={{top: 20}}
                              style={{margin: "0 auto"}}>
                        <XAxis dataKey="year" tickLine={false} />
                        {/*<YAxis allowDecimals={false} />*/}
                        <Bar dataKey="count" shape={renderBar} onClick={(data,index,e)=>{
                            stopPropagationUtil(e)
                            setFiltersObj(old=>({
                                ...old,
                                startYear: Number(data.year),
                                endYear: undefined
                            }))
                            updateFilters('year', data.year)
                        }}>
                            <LabelList dataKey="count" position="top" content={ChartLabel} />
                        </Bar>
                    </BarChart>
                    <PublicationsList data={data}
                                      filters={filtersObj}
                                      setData={handler=>{
                                          setData(handler)
                                          setShouldRefresh(true)
                                      }}
                                      setRemovedCount={setRemovedCount}
                                      personId={props.person.id}
                                      sortPublications={sortPublications}
                    />
                </>}
            </div>
        </Spin>
    </Modal>
}