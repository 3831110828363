/* eslint-disable jsx-a11y/anchor-is-valid */
import {useApi} from "./ApiProvider";
import {useEffect, useState} from "react";
import {DivisionAdditionalStats, DivisionStatsProjection, FiltersDto} from "../generated/api";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {augmentColors} from "../utils/colors";
import {Segmented} from "antd";
import {SegmentedValue} from "antd/es/segmented";
import Column from "antd/es/table/Column";
import {numberSorter, textSorter} from "../utils/utils";
import {MultiDivisionPopup} from "./MultiDivisionPopup";
import {stopPropagationUtil} from "../utils/clicks";
import {FiltersUpdateContext, FiltersUpdateContextInterface} from "./FiltersComponent";
import {TableWithCopy} from "./TableWithCopy";

interface ChartDivisionsProps {
    filters: FiltersDto
    refreshKey: number
    metrics?: boolean
}

interface DivisionWidgetProps {
    filters: FiltersDto
    refreshKey: number
    chartOrTable: SegmentedValue
    showCitationColumns?: boolean
}

function DivisionWidget(props: DivisionWidgetProps) {
    const api = useApi()

    const [data, setData] = useState<(DivisionStatsProjection & DivisionAdditionalStats & {color: string} )[]>()
    const [additionalData, setAdditionalData] = useState<DivisionAdditionalStats[]>();
    const [divisionColors, setDivisionColors] = useState<{[key: number]: string}>()
    const [multiPopupOpen, setMultiPopupOpen] = useState(false);
    const [activeIndexes, setActiveIndexes] = useState<number[]>([])
    const [activeIds, setActiveIds] = useState<Set<number>>(new Set<number>())
    const [filters, setFilters] = useState(props.filters)

    useEffect(()=>{
        const listener = (e: Event)=>{
            setFilters((e as CustomEvent).detail)
        }
        document.addEventListener('filtersUpdated', listener)
        return ()=>{
            document.removeEventListener('filtersUpdated', listener)
        }
    }, [])

    useEffect(()=>{
        api.listDivisions().then(response=>{
            const data = [...response.data, {id: -1, color: undefined}]

            const augment = augmentColors(data.map(x=>x.color))
            setDivisionColors(Object.fromEntries(data.map((x, i)=>[x.id, augment[i]])))
        });
    }, [api])

    useEffect(()=>{
        if (data) {
            let _activeIndexes: number[] = []
            let _activeIds=new Set<number>()
            filters.division?.forEach(division=> {
                    if (division.includes("|")) {
                        const id = Number(division.split("|")[0])
                        _activeIndexes.push(data.findIndex(x => x.id === id))
                        _activeIds.add(id)
                    } else {
                        const fixed = (division.startsWith("~") ? division.substring(1) : division).toLowerCase()
                        data.forEach((r,i)=>{
                            if (r.title?.toLowerCase().includes(fixed)) {
                                _activeIndexes.push(i)
                                _activeIds.add(r.id)
                            }
                        })
                    }
                }
            )
            setActiveIndexes(_activeIndexes)
            setActiveIds(_activeIds)
        }
    }, [data, filters])

    useEffect(()=> {
        if (props.chartOrTable==='Table') {
            if (additionalData === undefined) {
                setAdditionalData([]);
                api.divisionAdditionalStats().then(response=>{
                    setAdditionalData(response.data);
                    const additionalMap = Object.fromEntries(response.data.map(x=>[x.id, x]))
                    setData(oldData=>(oldData??[]).map(x=>({...x, ...additionalMap[x.id]})))
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, props.chartOrTable, props.refreshKey])

    useEffect(()=>{
        if (divisionColors) {
            api.distributionDivision(filters).then(response => {
                const additionalMap = Object.fromEntries((additionalData??[]).map(x=>[x.id, x]))
                setData(response.data.map(x => ({...x, color: divisionColors[x.id], title: x.title ?? "Multiple", ...additionalMap[x.id]})))
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshKey, api, divisionColors, filters.startYear, filters.endYear, filters.minCitations, filters.minNp, filters.minRcr, filters.paper, filters.journal/*, filters.division*/, filters.position, filters.rank, filters.hypothesis, filters.types, filters.researchArticle])

    function getColumnText(context: FiltersUpdateContextInterface, record: DivisionStatsProjection, value: string, pos: string) {
        if (record.id===-1) return value;
        if (!value) return undefined;
        return <a onClick={()=>{
            context.addFilter?.(['division:' + record.id + "|" + record.title, "position:"+pos])
        }}>{value}</a>
    }

    function isActiveId(id: number) {
        return activeIds.has(id)
    }

    return <FiltersUpdateContext.Consumer>
        {context=><div style={{flex: 1, position: 'relative', display: 'flex'}}>
                <MultiDivisionPopup isOpen={multiPopupOpen} onClose={()=>setMultiPopupOpen(false)} startYear={filters.startYear} endYear={filters.endYear}/>
                {props.chartOrTable==='Chart' && <>
                <ResponsiveContainer width="70%" height="100%"  className={"absolute-pos"}>
                    <PieChart width={500} height={400} onClick={()=>context.removeFilter?.('division')}>
                        <Pie animationEasing={"ease-in-out"} animationDuration={400} dataKey={'count'} nameKey={'title'} data={data}
                            //label={renderLabel}
                             activeIndex={activeIndexes}
                             activeShape={{className: "selected"}}
                             onClick={(d, _, e)=>{
                                 stopPropagationUtil(e)
                                 if (d.id===-1) {
                                     setMultiPopupOpen(true)
                                 } else {
                                     context.addFilter?.(['division:' + d.id + "|" + d.title])
                                 }
                             }}
                        >
                            {data?.map(entry => (
                                <Cell key={entry.id} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
                <div>
                    <ul style={{listStyle: 'none'}} className={"legend"}>
                        {data?.slice(0).sort((a,b)=>(a.title??"").localeCompare(b.title??""))
                            .map(x=><li key={x.id} className={isActiveId(x.id)?"active":""}
                                         onClick={(e)=>{
                                             stopPropagationUtil(e)
                                             if (x.id===-1) {
                                                 setMultiPopupOpen(true)
                                             } else {
                                                 context.addFilter?.(['division:' + x.id + "|" + x.title])
                                             }

                                         }}>
                            <div className="legend-circle" style={{backgroundColor: x.color}}></div>
                                &nbsp;
                            <span style={{color: x.color}} className={"title"}>{x.title}</span>
                            <span className={"legend-count"}>({x.count})</span>
                        </li>)}
                    </ul>
                </div>
            </>}
            {props.chartOrTable==='Table' &&
                <TableWithCopy dataSource={data} size={"small"} pagination={false} scroll={{y: '100%'}} className={"division-publications-table"} >
                    <Column title={"Division"} sorter={textSorter('title')} width={"30%"}
                            render={record=><a onClick={()=>{
                                if (record.id===-1) {
                                    setMultiPopupOpen(true)
                                } else {
                                    context.addFilter?.(['division:' + record.id + "|" + record.title])
                                }
                            }}>{record.title}</a>}
                    />
                    <Column title={"Total"} dataIndex={'count'} sorter={numberSorter('count')} />
                    <Column title={"1st"} dataIndex={'first'} sorter={numberSorter('first')} render={(value, record)=>{
                        return getColumnText(context, record, value, '1st')
                    }}/>
                    <Column title={"2nd"} dataIndex={'second'} sorter={numberSorter('second')} render={(value, record)=>{
                        return getColumnText(context, record, value, '2nd')
                    }}/>
                    <Column title={"Last"} dataIndex={'last'} sorter={numberSorter('last')} render={(value, record)=>{
                        return getColumnText(context, record, value, 'last')
                    }}/>
                    {props.showCitationColumns && <Column title={"h-index"}
                                                    dataIndex={"hindex"}
                                                    sorter={numberSorter('hindex')}/>}
                    {props.showCitationColumns && <Column title={"mRCR"}
                                                    dataIndex={"mrcr"}
                                                    render={x=>x?x.toFixed(1):''}
                                                    sorter={numberSorter('mrcr')}/>}
                </TableWithCopy>
            }
        </div>}
    </FiltersUpdateContext.Consumer>
}

export function ChartDivisions(props: ChartDivisionsProps) {
    const [chartOrTable, setChartOrTable] = useState<SegmentedValue>('Chart')
    return <>
            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
            <div className={"chart-title"}>
                By Division
                <Segmented options={['Chart', 'Table']}
                           onChange={value => setChartOrTable(value)}
                           style={{marginLeft: 10}} />
            </div>
            <DivisionWidget refreshKey={props.refreshKey} filters={props.filters} showCitationColumns={props.metrics} chartOrTable={chartOrTable} />
        </div>
    </>
}