import * as React from "react";
import {useState} from "react";
import {Button, Checkbox, Flex, Popover} from "antd";
import {CloseOutlined} from "@ant-design/icons";

const Types: string[] = ['Review',
'Systematic Review',
'Multicenter Study',
'Meta-Analysis',
'Observational Study',
'Scoping Review',
'Clinical Trial',
'Randomized Controlled Trial',
'Evaluation Study',
'Validation Study',
'Dataset']

interface TypeTagProps {
    closeSelectDropdown: ()=>void
    onRemove: (event?: React.MouseEvent<HTMLElement, MouseEvent>)=>void
    value: string
    setValue: (value: string)=>void
    typeOpen: boolean
    setTypeOpen: (open: boolean)=>void
}

export function TypeTag(props: TypeTagProps) {
    const [type, setType] = useState<string[]>(parseValue(props.value))
    function parseValue(value: string) {
        if (value.startsWith("type:")) {
            const val = props.value.split(':', 2)[1].trim()
            return val.split(',').map(x=>x.trim()).filter(x=>x!=="")
        }
        return []
    }

    function getButtonTitle() {
        if (type.length===0) return "Choose Publication Types";
        return type.filter(x=>x!==undefined).join(", ");
    }

    function refresh(value: string[]) {
        props.setValue(value.join(","))
    }

    return <div className={'tag'} onMouseDown={e=>e.stopPropagation()}>
        <Popover content={
            <Flex vertical={true}>
                {Types.map(kv =>
                    <Checkbox key={kv} checked={type.includes(kv)} onChange={e=>{
                        if (e.target.checked) {
                            setType(old=>{
                                const n = [...old, kv]
                                refresh(n)
                                return n
                            })
                        } else {
                            setType(old=>{
                                const n = old.filter(x=>x!==kv)
                                refresh(n)
                                return n
                            })
                        }
                    }}>{kv}</Checkbox>
                )}
            </Flex>
            }
                 style={{width: 400}}
                 arrow={false}
                 trigger={'click'}
                 placement={"bottomLeft"}
                 destroyTooltipOnHide={true}
                 align={{offset: [0, 6]}}
                 onOpenChange={(open)=>{
                     if (open) {
                         props.closeSelectDropdown()
                     }
                     props.setTypeOpen(open)
                 }}
                 open={props.typeOpen}
        >
            <Button size={"small"} type={'link'}>{getButtonTitle()}</Button>
        </Popover>
        <Button type={'text'} size={"small"} className={'close-tag'} onClick={props.onRemove}><CloseOutlined/></Button>
    </div>
}

