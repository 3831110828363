import React, {createContext, useContext, useMemo, useState} from "react";
import {Navigation} from "./Navigation";
import {BreadcrumbItemType} from "antd/es/breadcrumb/Breadcrumb";
import {useNavigate} from "react-router-dom";
import {SettingsHolderContext} from "./SettingsHolder";
import {constructFilters, FiltersComponent, FiltersUpdateContext} from "./FiltersComponent";
import {DefaultLayout} from "./layouts/DefaultLayout";
import {FiltersDto} from "../generated/api";
import {DefaultLayoutWithMetrics} from "./layouts/DefaultLayoutWithMetrics";
import {JournalsLayout} from "./layouts/JournalsLayout";
import {YearsLayout} from "./layouts/YearsLayout";

export const AnalyticsContext = createContext<{
    refresh?: ()=>void
}>({})

export function Analytics() {
    const [refreshKey, setRefreshKey] = useState(0)
    const [filters, setFilters] = useState<string[]>([])
    const [filtersObj, setFiltersObj] = useState<FiltersDto>({})
    const [layout, setLayout] = useState('default')
    const navigate = useNavigate();

    const settingsHolder = useContext(SettingsHolderContext)

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }

    const refreshMemo = useMemo(()=>{
        return {refresh: () => setRefreshKey(old=>old+1)}
    }, [setRefreshKey])

    const breadcrumbs: BreadcrumbItemType[] = [
        {title: 'Publications'}
    ]

    function renderLayout(layout: string, filters: FiltersDto) {
        switch (layout) {
            case 'years':
                return <YearsLayout filters={filters} refreshKey={refreshKey}/>
            case 'journals':
                return <JournalsLayout filters={filters} refreshKey={refreshKey}/>
            case 'metrics':
                return <DefaultLayoutWithMetrics filters={filters} refreshKey={refreshKey}/>
            default:
                return <DefaultLayout filters={filters} refreshKey={refreshKey}/>
        }
    }

    function updateFilters(val: string[]) {
        const filtersObj1 = constructFilters(val)
        setFiltersObj(filtersObj1)
        document.dispatchEvent(new CustomEvent('filtersUpdated', {detail: filtersObj1}))
    }

    function addFilter(vals: string[]) {
        setFilters(old => {
            let clone = [...old]
            vals.forEach(val=> {
                const param = val.split(':', 2)[0].trim()
                let found = false
                for (let i = clone.length - 1; i >= 0; i--) {
                    if (clone[i].split(':')[0].trim() === param) {
                        if (found) {
                            clone.splice(i, 1)
                        } else {
                            found = true
                            clone[i] = val
                        }
                    }
                }
                if (!found) {
                    clone.push(val)
                }
                updateFilters(clone)
            })
            return clone
        })
    }

    function removeFilter(val: string) {
        setFilters(old=>{
            const ret = old.filter(x=>!x.startsWith(val))
            updateFilters(ret)
            return ret
        })

    }

    function setFiltersInt(val: string[]) {
        setFilters(val)
        updateFilters(val)
    }

    const filtersUpdate = useMemo(()=>({addFilter, removeFilter}), [])

    return <Navigation breadcrumbs={breadcrumbs} title={"Publications"}>
        <FiltersComponent filters={filters} setFilters={setFiltersInt} layout={layout} setLayout={setLayout}/>
        <FiltersUpdateContext.Provider value={filtersUpdate}>
            <AnalyticsContext.Provider value={refreshMemo}>
                <React.Fragment key={layout}>{renderLayout(layout, filtersObj)}</React.Fragment>
            </AnalyticsContext.Provider>
        </FiltersUpdateContext.Provider>
    </Navigation>
}