import {useApi} from "./ApiProvider";
import React, {useEffect, useRef, useState} from "react";
import {PersonNameDtoWithId, PublicationDto, VerifyResultDto} from "../generated/api";
import {Button, Checkbox, Modal, Typography} from "antd";
import './AddMissingMultiplePopup.scss'
import {PublicationsList} from "./PublicationsList";

interface AddMissingMultiplePopupProps {
    open: boolean
    onClose: (shouldUpdate: boolean)=>void
    person?: PersonNameDtoWithId
    allPublications: PublicationDto[] | undefined
}

export function AddMissingMultiplePopup(props: AddMissingMultiplePopupProps) {
    const api = useApi()
    const [verifying, setVerifying] = useState(false)
    const [importing, setImporting] = useState(false);
    const [verifyResult, setVerifyResult] = useState<{
        data: VerifyResultDto
        extra: PublicationDto[]
        errors: number
        warnings: number
    }>()
    const [reviewExtraStep, setReviewExtraStep] = useState(false)
    const [reviewExtraCheck, setReviewExtraCheck] = useState(false)
    const listDivRef = useRef<HTMLDivElement | null>(null)

    const inputEditable = document.getElementsByClassName("input-editable").item(0)

    useEffect(() => {
        const obs = new MutationObserver((records)=>{
            // @ts-ignore
            if (!window.ignoreNextObserve) {
                setVerifyResult(undefined)
                records.forEach(x => {
                    let parent: HTMLElement | null = x.target as HTMLElement
                    while (parent && !parent.className?.includes('input-editable')) {
                        if (parent.className?.includes("error") ||
                            parent.className?.includes("warn")) {
                            parent.className = "";
                            parent.title = "";
                        }
                        parent = parent.parentElement;
                    }
                })
            }
        })
        const el = document.getElementsByClassName("input-editable").item(0)
        if (el) {
            obs.observe(el, {
                subtree: true,
                childList: true,
                characterData: true
            })
            return ()=>{
                obs.disconnect()
            }
        }
    }, [inputEditable]);

    function doImport() {
        if (props.person?.id && verifyResult) {
            if (verifyResult.data.publications.length>0) {
                setImporting(true)
                api.addManual(props.person.id, 'added', verifyResult.data.publications.map(x => x.publication.pmid)).then(() => {
                    setImporting(false)
                    if (reviewExtraCheck && verifyResult.extra.length>0) {
                        // sometimes chrome plugins change the tree
                        // @ts-ignore
                        window.ignoreNextObserve = true
                        setReviewExtraStep(true)
                    } else {
                        props.onClose(true);
                    }
                })
            } else if (reviewExtraCheck && verifyResult.extra.length>0) {
                // @ts-ignore
                window.ignoreNextObserve = true
                setReviewExtraStep(true)
            } else {
                props.onClose(true);
            }
        }
    }

    function verify() {
        if (props.person?.id && listDivRef.current) {
            const txtList = listDivRef.current.innerText;
            setVerifying(true)
            setVerifyResult(undefined)
            api.verifyList(props.person.id, txtList, {
                headers: { 'Content-Type': 'text/plain' }
            }).then(response=>{
                if (listDivRef.current) {
                    // ugly hack :(
                    // @ts-ignore
                    window.ignoreNextObserve = true
                    if (listDivRef.current) {
                        listDivRef.current.innerHTML = response.data.resultHtml
                        setTimeout(() => {
                            // @ts-ignore
                            window.ignoreNextObserve = false
                        }, 100)
                    }
                    setVerifying(false)
                    const found = new Set(response.data.publications.map(x=>x.publication.pmid))
                    setVerifyResult({
                        data: response.data,
                        extra: (props.allPublications??[]).filter(x=>!found.has(x.pmid)),
                        errors: listDivRef.current.getElementsByClassName('error').length,
                        warnings: listDivRef.current.getElementsByClassName('warn').length,
                    })
                }
            });
        }
    }

    function getImportText() {
        if (verifyResult!==undefined) {
            const all = verifyResult.data.publications.length
            const already = verifyResult.data.publications.filter(x=>x.alreadyExists).length
            if (!already) return all
            return (all-already) + " new, " + already + " updated"
        }
        return undefined;
    }

    return <Modal open={props.open}
                  title={reviewExtraStep?<>Review extra publications</>:<>Import Publication</>}
                  onCancel={()=>props.onClose(false)}
                  destroyOnClose={true}
                  width={960}
                  afterClose={()=>{
                      setImporting(false)
                      setVerifying(false)
                      setVerifyResult(undefined)
                      setReviewExtraCheck(false)
                      setReviewExtraStep(false)
                  }}
                  footer={<div style={{display: 'flex', alignItems: "center"}}>
                      {!reviewExtraStep && <Checkbox checked={reviewExtraCheck}
                                    onChange={e=>setReviewExtraCheck(e.target.checked)}
                                    disabled={verifyResult?.extra?.length===0}
                          >
                              Review other publications found by Faculty Analytics
                              {verifyResult?.extra!==undefined && <b> ({verifyResult.extra.length})</b>}
                          </Checkbox>}
                          <div className={"spacer"} />
                      {!reviewExtraStep &&
                          <Button key="cancel" onClick={() => props.onClose(false)}>
                              Cancel
                          </Button>}
                      {reviewExtraStep &&
                          <Button key="cancel" type={"primary"} onClick={() => props.onClose(true)}>
                              Close
                          </Button>}
                      {verifyResult && !reviewExtraStep && <>
                              <Button key="submit" type={"primary"}
                                  onClick={doImport}
                                  loading={importing}
                                  disabled={verifyResult.data.publications.length===0
                              && (verifyResult.extra.length===0 || !reviewExtraCheck)}
                              >
                                  {verifyResult.data.publications.length!==0 && <>
                                        Import ({getImportText()})
                                  </>}
                                  {verifyResult.data.publications.length===0 && <>
                                      Review
                                  </>}
                              </Button>
                          </>
                      }
                      {!verifyResult &&
                          <Button key="verify" type="primary"
                                  onClick={verify}
                                  disabled={verifying}
                                  loading={verifying}
                          >
                              {verifying?<>Validating</>:<>Validate</>}
                          </Button>
                      }
                      </div>}
    >
        <div style={{height: 550, display: 'flex', flexDirection: 'column'}}>
        {!reviewExtraStep && <>
            <div contentEditable={true}
                 onPaste={e=>{
                     e.preventDefault();
                     const text = e.clipboardData.getData('text/plain');
                     if (listDivRef.current) {
                         document.execCommand("insertHTML", false, text.split('\n').map(x=>"<div>" + x + "</div>").join(''));
                         //listDivRef.current.innerHTML = text.split('\n').map(x => "<div>" + sanitize(x) + "</div>").join('')
                     }
                 }}
                 className={"input-editable"}
                 data-placeholder={"Please provide the list of publications.\n\nThe list can be either the plain list of PMIDs or list of publications in AMA format, for example:\n\n" +
                     "11111111\n22222222\n33333333\n\nor\n\n" +
                                 "1. Smith JK, Anderson LM, Brown EF. The impact of exercise on cardiovascular health. Circulation. 2019;135(8):789-802. PMID: 98765432.\n\n" +
                                 "2. Smith AB, Johnson CD, Jones EF. The role of genetics in cancer susceptibility. JAMA Oncol. 2021;7(3):321-335. PMID: 12345678."}
                 onInput={e=>{
                     if (e.currentTarget.innerHTML.trim()==='<br>') {
                         e.currentTarget.innerHTML=''
                     }
                 }}
                 ref={listDivRef}
            />
            {verifyResult && (verifyResult?.warnings??0)>0 &&  <div style={{marginTop: 10}}>
                <Typography.Text type={"warning"}>For papers marked in yellow ({verifyResult.warnings}) author's last name doesn't match any of the publication's author.</Typography.Text>
            </div>}
            {verifyResult && (verifyResult.errors??0)>0 && <div style={{marginTop: 10}}>
                <Typography.Text type={"danger"}>All lines marked in red ({verifyResult.errors}) will be ignored</Typography.Text>
            </div>}
            {verifyResult && !verifyResult?.errors && <div style={{marginTop: 10}}>
                <Typography.Text type={"success"}>Validated successfully. Click Import button to proceed.</Typography.Text>
            </div>}
        </>}
        {reviewExtraStep && verifyResult?.extra && (verifyResult?.extra?.length??0)>0 && props.person && <div style={{overflow: "auto"}}>
            <PublicationsList personId={props.person.id}
                               data={verifyResult.extra}
                               sortPublications={'pmid'}
                               showAffiliations={true}
                               setData={(handler)=>{
                                  setVerifyResult(prevVerifyResult=>prevVerifyResult?{
                                      ...prevVerifyResult,
                                      extra: handler(prevVerifyResult.extra)??[]
                                  }:undefined)
                               }}/>
        </div>}
        </div>
    </Modal>
}