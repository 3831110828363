import * as React from "react";
import {useState} from "react";
import {Button, Select} from "antd";
import {FlattenOptionData} from "rc-select/lib/interface";
import {BaseOptionType} from "rc-select/lib/Select";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {YearsTag} from "../filters/YearsTag";
import {CloseOutlined} from "@ant-design/icons";
import './ProjectFiltersComponent.scss'
import {SettingsContext} from "../Navigation";

export interface ProjectFiltersProps {
    setFilters: (value: string[]) => void
    filters: string[]
    layout: string
    setLayout: (value: string) => void
}

export interface ProjectFiltersUpdateContextInterface {
    addFilter?: (val: string[])=>void
    removeFilter?: (val: string)=>void
}

export const ProjectFiltersUpdateContext=React.createContext<ProjectFiltersUpdateContextInterface>({})

export function ProjectFiltersComponent(props: Readonly<ProjectFiltersProps>) {
    const [yearsOpen, setYearsOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const multiValues = [{
        value: 'title',
        labelContains: 'Project title contains',
        label: 'Project title'
    }, {
        value: 'division',
        labelContains: 'Division name contains',
        label: 'Division'
    }]

    const singletonValues = ['year']

    const defaultOptions = [{
        label: 'Filter by year',
        value: 'year'
    }, {
        label: 'Filter by project title',
        value: 'title'
    }, {
        label: 'Filter by division',
        value: 'division'
    }]
    const [options, setOptions] = useState<BaseOptionType[]>(defaultOptions)

    function onChange(val: string[]) {
        let match = false;
        if (val.length) {
            const found = multiValues.find(x=>val[val.length-1] === x.value)
            if (found) {
                setSearchValue(found.value + ": ")
                refreshOptions(found.value +": ")
                match = true
            }
        }
        if (!match) {
            setSearchValue('')
            refreshOptions('')
            setValueInt(val)
        }
        return false;
    }

    function setValueInt(f: string[]) {
        props.setFilters(f)
    }

    function optionRender(oriOption: FlattenOptionData<BaseOptionType>) {
        return <div>{oriOption.label}</div>
    }

    function tagRender(tagProps: CustomTagProps) {
        if (tagProps.value==='year' || tagProps.value.startsWith('year:')) {
            return <YearsTag value={tagProps.value}
                             setValue={(value)=>{
                                 setValueInt(props.filters.map(x=>{
                                            if (x==='year' || x.startsWith("year:")) return 'year:'+value
                                            return x
                                        }))
                             }}
                             closeSelectDropdown={()=>setIsOpen(false)}
                             onRemove={tagProps.onClose}
                             yearsOpen={yearsOpen}
                             setYearsOpen={setYearsOpen}
                             showAcademic={false}
            />
        } else if (tagProps.value.startsWith('division:') && tagProps.value.includes('|')) {
            return <div className={'tag'}
                        style={{paddingLeft: 8,
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center'}}
            >division: {tagProps.value.split('|')[1]}
                <Button type={'text'} size={"small"} className={'close-tag'} onClick={tagProps.onClose}><CloseOutlined/></Button>
            </div>
        }
        return <div className={'tag'}
                    style={{paddingLeft: 8,
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center'}}
        >{tagProps.label}
            <Button type={'text'} size={"small"} className={'close-tag'} onClick={tagProps.onClose}><CloseOutlined/></Button>
        </div>
    }

    function dropdownRender(menu: React.ReactElement) {
        return <div>{menu}</div>
    }

    function refreshOptions(searchValue?: string, addedVal?: string, removedVal?: string) {
        if (!searchValue) {
            const has = Object.fromEntries(singletonValues.map(z=>
                [z, addedVal===z || (props.filters.some(x=>x.startsWith(z)) && !removedVal?.startsWith(z))]
            ))
            setOptions(defaultOptions.filter(x=>{
                return !has[x.value];

            }))
        } else {
            let anyMatch = false;
            for (let x in multiValues) {
                if (searchValue.startsWith(multiValues[x].value+":")) {
                    const param = searchValue.split(":", 2)
                    let par = param[1].trim()
                    if (par.startsWith("~")) par = par.substring(1)
                    anyMatch = true
                    setOptions([{
                        label: <>{multiValues[x].labelContains}: <b>{par}</b></>,
                        value: multiValues[x].value + ": ~" + par
                    }])
                    break
                }
            }
            if (!anyMatch) {
                setOptions(multiValues.map(x=>({
                    label: <>{x.labelContains}: <b>{searchValue}</b></>,
                    value: x.value+": ~" + searchValue
                })))
            }
        }
    }

    function onSearch(value: string) {
        setSearchValue(value)
        refreshOptions(value)
    }

    function onSelect(val: string) {
        if (val==='year') {
            setIsOpen(false)
            refreshOptions(undefined, val)
            setYearsOpen(true)
        }
    }

    function onDeselect(val: string) {
        refreshOptions(undefined, undefined, val)
    }

    return <SettingsContext.Consumer>
        {settings => <div style={{display: "flex", gap: 10, alignItems: "center", paddingBottom: 15}}>
            <span>Layout</span>
            <Select
                style={{width: 145}}
                options={[
                    {label: 'Default', value: 'default'},
                    {label: 'With Budgets', value: 'budgets'}
                ]}
                value={props.layout}
                onChange={props.setLayout}
            />
            <span>Filters</span>
            <Select
                className={'filters'}
                open={isOpen}
                onDropdownVisibleChange={(open)=>{
                    setIsOpen(open)
                }}
                style={{flex: 1}}
                value={props.filters}
                onSelect={onSelect}
                onDeselect={onDeselect}
                mode={'multiple'}
                onSearch={onSearch}
                searchValue={searchValue}
                placeholder={'Click to add filters'}
                onChange={onChange}
                options={options}
                listHeight={32*9}
                filterOption={false}
                optionRender={optionRender}
                tagRender={tagRender}
                dropdownRender={dropdownRender}
                suffixIcon={<Button type={'link'} onClick={()=>setValueInt([])}>
                    Reset
                </Button>}
            />
        </div>}
    </SettingsContext.Consumer>
}