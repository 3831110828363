// noinspection RedundantIfStatementJS

import {Segmented, Select} from "antd";
import {PublicationDto, PublicationListItemDto} from "../generated/api";
import {useRolesProvider} from "../utils/keycloakUtils";

type IPublication = PublicationListItemDto | PublicationDto

function comparePMID(a: IPublication, b: IPublication) {
    return Number(b.pmid)-Number(a.pmid)
}

function comparePMIDRev(a: IPublication, b: IPublication) {
    return -comparePMID(a, b)
}

function compareRCR(a: IPublication, b: IPublication) {
    return Number(b.relativeCitationRatio)-Number(a.relativeCitationRatio)
}

function compareRCRRev(a: IPublication, b: IPublication) {
    return -compareRCR(a, b)
}

function compareNP(a: IPublication, b: IPublication) {
    return Number(b.nihPercentile)-Number(a.nihPercentile)
}

function compareNPRev(a: IPublication, b: IPublication) {
    return -compareNP(a, b)
}

function compareCit(a: IPublication, b: IPublication) {
    return Number(b.citations)-Number(a.citations)
}

function compareCitRev(a: IPublication, b: IPublication) {
    return -compareCit(a, b)
}

function compareScore(a: IPublication, b: IPublication) {
    return Number(b.score??20)-Number(a.score??20)
}

function compareScoreRev(a: IPublication, b: IPublication) {
    return -compareScore(a, b)
}

const sortFunctions: {[key: string]: (a: IPublication,b: IPublication)=>number}={
    'pmid': comparePMID,
    '-pmid': comparePMIDRev,
    'rcr': compareRCR,
    '-rcr': compareRCRRev,
    'np': compareNP,
    '-np': compareNPRev,
    'cit': compareCit,
    '-cit': compareCitRev,
    'score': compareScore,
    '-score': compareScoreRev,
}

export function getSortFunc(s: string) {
    if (sortFunctions.hasOwnProperty(s)) {
        return sortFunctions[s]
    }
    return sortFunctions['pmid'];
}

export function FilterAndSort(props: {
    sortPublications: string
    setSortPublications: (value: (string)) => void
    position?: string;
    setPosition?: (value: (string)) => void
}) {
    const rolesProvider = useRolesProvider();

    const adminOptions: any[] = rolesProvider.isAdmin()?[{
        label: 'Sort by Score (higher first)',
        value: 'score'
    }]:[]

    return <>
        {
            props.setPosition!==undefined &&
            <Segmented options={['all', '1st', '2nd', 'last']} value={props.position ?? 'all'}
                    onChange={x => props.setPosition?.(x.toString())}/>
        }
        <Select
            options={[{
                label: 'Sort by PMID (newest first)',
                value: 'pmid'
            }, {
                label: 'Sort by PMID (oldest first)',
                value: '-pmid'
            }, {
                label: 'Sort by RCR (highest first)',
                value: 'rcr'
            }, {
                label: 'Sort by RCR (lowest first)',
                value: '-rcr'
            }, {
                label: 'Sort by NP (highest first)',
                value: 'np'
            }, {
                label: 'Sort by NP (lowest first)',
                value: '-np'
            }, {
                label: 'Sort by Citations (highest first)',
                value: 'cit'
            }, {
                label: 'Sort by Citations (lowest first)',
                value: '-cit'
            }, ...adminOptions]}
            value={props.sortPublications}
            onSelect={props.setSortPublications}
            listHeight={300}
        />
    </>;
}

export function checkPosition(selectedPosition: string[] | undefined, pos: number | undefined, cnt: number) {
    if (selectedPosition=== undefined) return true;
    if (selectedPosition.includes('all')) return true;
    if (pos===0 && selectedPosition.includes('1st')) return true;
    if (pos===1 && selectedPosition.includes('2nd')) return true;
    if (pos===cnt-1 && cnt>2 && selectedPosition.includes('last')) return true;
    return false;
}