/* eslint-disable jsx-a11y/anchor-is-valid */
import {Input, Segmented, Table} from "antd";
import Column from "antd/es/table/Column";
import {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {FiltersDto, PersonNameDtoWithId, PersonYearsStatsDto, PublicationListItemDto} from "../generated/api";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {formatName, numberSorter, rankSorter, textSorter} from "../utils/utils";
import {PublicationsPopup} from "./PublicationsPopup";
import Highlighter from "react-highlight-words";
import {SegmentedValue} from "antd/es/segmented";
import {SettingsContext} from "./Navigation";
import {FilterAndSort, getSortFunc} from "./FilterAndSort";
import {stopPropagationUtil} from "../utils/clicks";
import {AnalyticsContext} from "./Analytics";
import {ExportButton} from "./ExportButton";
import {FiltersUpdateContext} from "./FiltersComponent";
import {ShortRanks} from "./People";
import {TableWithCopy} from "./TableWithCopy";

interface PeoplePublicationsProps {
    filters: FiltersDto
    refreshKey?: number;
    metrics?: boolean
}

function formatPubSuffixHtml(x: {pmid: string, nihPercentile?: number, relativeCitationRatio?: number, citations?: number}) {
    return "<span class='pmid-tag'>PMID:&nbsp;<a href='https://pubmed.ncbi.nlm.nih.gov/" + x.pmid + "/' target='_blank'' rel='noreferrer'>" + x.pmid + "</a></span>" +
        (x.nihPercentile?"<span> </span><span class='np-tag'>NP: " + x.nihPercentile + "</span>":"") +
        (x.relativeCitationRatio?"<span> </span><span class='rcr-tag'>RCR: " + x.relativeCitationRatio +"</span>":"") +
        (x.citations?"<span> </span><span class='citations-tag'>Cit: " + x.citations +"</span>":"")
}

function cleanHtml(str: string | undefined) {
    if (!str) return "";
    return str.replace(/[<>]/, " ")
}

function formatPubJournalHtml(x: {journal?: string, month?: string, year?: number}) {
    return "<div style='color: #aaa; font-style: italic'><span class='journal-link'>" + cleanHtml(x.journal) +"</span>, " + cleanHtml(x.month) + " " + x.year + "</div>"
}

export function formatPubJournal(x: {journal?: string, month?: string, year?: number}) {
    return <div style={{color: '#aaa', fontStyle: 'italic'}}>{x.journal}, {x.month} {x.year}</div>
}

export function formatPubSuffix(x: {pmid: string, nihPercentile?: number, relativeCitationRatio?: number, citations?: number}) {
    return <>
        <span className={'pmid-tag'}>PMID:&nbsp;<a href={"https://pubmed.ncbi.nlm.nih.gov/" + x.pmid + "/"}
                                                   target={"_blank"} rel="noreferrer">{x.pmid}</a></span>
        {!!x.nihPercentile && <><span> </span><span className={'np-tag'}>NP: {x.nihPercentile}</span></>}
        {!!x.relativeCitationRatio && <><span> </span><span className={'rcr-tag'}>RCR: {x.relativeCitationRatio}</span></>}
        {!!x.citations && <><span> </span><span className={'citations-tag'}>Cit: {x.citations}</span></>}
    </>
}


class CancelHandler {
    isCancelledProp = false;
    isCancelled=()=>this.isCancelledProp
    setCancelled=()=>{this.isCancelledProp=true}
}

export function PeoplePublications(props: PeoplePublicationsProps) {
    const api = useApi()
    const [data, setData] = useState<PersonYearsStatsDto[]>();
    const [listData, setListData] = useState<PublicationListItemDto[]>();
    const [searchText, setSearchText] = useState('');
    const [searchTextTitle, setSearchTextTitle] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<{
        person: PersonNameDtoWithId,
        position?: string,
        year?: number
    }>()
    const [currentCancelHandler, setCurrentCancelHandler] = useState<CancelHandler>()
    const [isLoading, setLoading] = useState(false)
    const [positionsOrYears, setPositionsOrYears] = useState<SegmentedValue>('Authorship')
    const [sortPublications, setSortPublications] = useState("pmid")

    useEffect(()=>{
        api.listPersonsByYearAndDivision(props.filters).then(response=>{
            setData(response.data)
        })
    }, [props.filters, props.refreshKey, api])

    function loadPagedData(page: number, cancelHandler: CancelHandler) {
        setLoading(true)
        api.listPublicationsByYearAndDivision(props.filters, page, 500).then(response => {
            if (cancelHandler.isCancelled()) return;
            if (response.data.length) {
                setListData(oldValue=>{
                    if (oldValue===undefined) return response.data
                    return [...oldValue, ...response.data]
                })
                if (response.data.length<500) {
                    setLoading(false)
                } else {
                    loadPagedData(page + 1, cancelHandler)
                }
            } else {
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        if (positionsOrYears==='List') {
            setListData(undefined)
            if (currentCancelHandler) {
                currentCancelHandler.setCancelled()
            }
            const newCancelHandler = new CancelHandler()
            setCurrentCancelHandler(newCancelHandler)
            loadPagedData(0, newCancelHandler);
        }
    }, [props.filters, api, positionsOrYears, props.refreshKey])

    function filteredData(): PersonYearsStatsDto[] | undefined {
        return data?.filter(x=>formatName(x).toLowerCase().includes(searchText.toLowerCase()));
    }

    function getNameColumn(width: string) {
        return <Column<PersonYearsStatsDto>
            width={width}
            title={
                <div className={"col-header"} onClick={e => stopPropagationUtil(e)}><span>Name</span>
                    <Input value={searchText} onChange={e => setSearchText(e.target.value)}
                           prefix={<SearchOutlined/>}
                           suffix={<a onClick={() => {
                               setSearchText("");
                           }}><CloseOutlined/></a>}
                    />
                </div>}
            sorter={textSorter('lastName')}
            render={(_, record) =>
                <a onClick={() => {
                    setSelectedPerson({person: record})
                }}>{
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={formatName(record)}
                    />
                }</a>
            }
        />;
    }


    function formatPublication(x: PublicationListItemDto, index: number) {
        return <div className={"allow-wrap"} dangerouslySetInnerHTML={{
            __html:
                "<div>" + index + ". " + cleanHtml(x.title) + " " + formatPubSuffixHtml(x) + "</div>" +
                "<div style='color: #aaa'>"+x.authorsList.map((z, idx)=>{
                    return "<span title='" + cleanHtml(z.affiliations?.join("\n\n")) + "'>" +
                        (z.inList?
                            ("<span class='highlight-author' data-idx='" + idx + "'>" + cleanHtml(z.lastName)+" "+cleanHtml(z.initials) + "</span>"):
                            (cleanHtml(z.lastName)+" "+cleanHtml(z.initials))) +
                    "</span>"
                }).join(', ')+ "</div>" +
                formatPubJournalHtml(x)
        }}/>

    }

    function sortAndFilter(listData: PublicationListItemDto[] | undefined, searchTextTitle: string, sort: string) {
        if (!listData) return [];
        const searchText = searchTextTitle.toLowerCase();
        return listData.filter(x=>x.title.toLowerCase().includes(searchText) || x.pmid===searchText)
            .sort(getSortFunc(sort))
    }

    return <SettingsContext.Consumer>
        {settings=><div style={{flex:1, display: 'flex', flexDirection: 'column', marginLeft: 20}}>
            <div className={"chart-title"}>Publications
                <Segmented options={['Authorship', 'List']}
                           value={positionsOrYears}
                            onChange={value => setPositionsOrYears(value)}
                            style={{marginLeft: 10}}/>
                <div style={{position: 'relative', height: '100%'}}>
                    <div style={{position: 'absolute', height: '100%'}}>
                        {positionsOrYears==='List' && <div style={{position: "relative", top: 3}}><ExportButton filters={props.filters} /></div>}
                    </div>
                </div>
            </div>
            {settings && <AnalyticsContext.Consumer children={
                analyticsContext => <PublicationsPopup filters={{
                    ...props.filters,
                    position: selectedPerson?.position?[selectedPerson.position]:props.filters.position
                }}
                                              person={selectedPerson?.person}
                                              onClose={shouldRefresh => {
                                                  setSelectedPerson(undefined)
                                                  if (shouldRefresh) analyticsContext.refresh?.()
                                              }}/>
            } />}
            {(positionsOrYears === 'Authorship') && data && <TableWithCopy size={"small"} scroll={{y: '100%'}} className={"people-publications-table"}
                                                                  dataSource={filteredData()}
                                                                  rowKey={'id'}
                                                                  style={{flex: 1}} pagination={false}>
                {getNameColumn("30%")}
                <Column title={"Rank"}
                        dataIndex={"academicRank"}
                        sorter={rankSorter('academicRank')}
                        render={v=>ShortRanks[v]}
                />
                <Column title={"Total"}
                        dataIndex={"count"}
                        defaultSortOrder={'descend'}
                        sorter={numberSorter('count')}/>
                <Column title={"1st"} dataIndex={"first"} sorter={numberSorter('first')} render={(v, r)=>
                    <a onClick={() => setSelectedPerson({person: r, position: '1st'})}>{v?v:""}</a>}/>
                <Column title={"2nd"} dataIndex={"second"} sorter={numberSorter('second')}  render={(v, r)=>
                    <a onClick={() => setSelectedPerson({person: r, position: '2nd'})}>{v?v:""}</a>}/>
                <Column title={"Last"} dataIndex={"last"} sorter={numberSorter('last')}  render={(v, r)=>
                    <a onClick={() => setSelectedPerson({person: r, position: 'last'})}>{v?v:""}</a>}/>
                {props.metrics && <Column title={"h-index"}
                                                dataIndex={"hindex"}
                                                sorter={numberSorter('hindex')}
                                                render={x=>x?x.toFixed(0):''}/>}
                {props.metrics && <Column title={"mCit"}
                                                dataIndex={"avgCitations"}
                                                render={x=>(x??0).toFixed(0)}
                                                sorter={numberSorter('avgCitations')}/>}
                {props.metrics && <Column title={"mRCR"}
                                                dataIndex={"meanRCR"}
                                                sorter={numberSorter('meanRCR')}
                                                render={x=>x?x.toFixed(1):''}/>}
                {props.metrics && <Column title={"wRCR"}
                                                dataIndex={"weightedRCR"}
                                                sorter={numberSorter('weightedRCR')}
                                                render={x=>x?x.toFixed(0):''}
                />}
            </TableWithCopy>}
            {positionsOrYears === 'List' &&
            <FiltersUpdateContext.Consumer>
                { context=> <Table
                    rowKey={'pmid'}
                    size={"small"} scroll={{y: '100%'}}
                    className={"people-publications-table"}
                    dataSource={sortAndFilter(listData, searchTextTitle, sortPublications)}
                    style={{flex: 1}}
                    pagination={false}
                    loading={isLoading ? {tip: <span style={{color: '#777'}}>Loading: {listData?.length??0} publications</span>, size: 'large'} : undefined}
                    locale={{ emptyText: <></> }}
                >
                    <Column<PublicationListItemDto>
                        onCell={data1 => ({
                            onClick: e=>{
                                const target = e.target as HTMLElement
                                const attrValue = target.getAttribute("data-idx")
                                if (attrValue!==undefined && attrValue!==null) {
                                    api.getPersonByPmidAndPosition(data1.pmid, Number(attrValue)).then(response =>
                                        setSelectedPerson({person: response.data})
                                    )
                                }
                                const className = target.getAttribute("class")
                                if (className==="journal-link") {
                                    context.addFilter?.(['journal: ' + target.innerText])
                                }
                            }
                        })}
                        title={
                            <div className={"col-header"} onClick={e => stopPropagationUtil(e)}><span>Title</span>
                                <Input value={searchTextTitle} onChange={e => setSearchTextTitle(e.target.value)}
                                       prefix={<SearchOutlined/>}
                                       suffix={<a onClick={() => {
                                           setSearchTextTitle("");
                                       }}><CloseOutlined/></a>}
                                />
                                <FilterAndSort sortPublications={sortPublications} setSortPublications={setSortPublications} />
                            </div>}
                        render={(_, record, index) =>
                            formatPublication(record, index+1)
                        }
                    />
                </Table>}
            </FiltersUpdateContext.Consumer>}
        </div>}
    </SettingsContext.Consumer>
}