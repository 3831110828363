/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type {Configuration} from './configuration';
import type {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    toPathString
} from './common';
import type {RequestArgs} from './base';
// @ts-ignore
import {BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequiredError} from './base';

/**
 * 
 * @export
 * @interface AlternativeNameDto
 */
export interface AlternativeNameDto {
    /**
     * 
     * @type {string}
     * @memberof AlternativeNameDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlternativeNameDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AlternativeNameDto
     */
    'middleName'?: string;
}
/**
 * 
 * @export
 * @interface AuthorDto
 */
export interface AuthorDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorDto
     */
    'foreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorDto
     */
    'initials'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorDto
     */
    'affiliations'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuthorForListDto
 */
export interface AuthorForListDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorForListDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorForListDto
     */
    'foreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorForListDto
     */
    'initials'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorForListDto
     */
    'affiliations'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorForListDto
     */
    'inList'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthorWithDivisionDto
 */
export interface AuthorWithDivisionDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorWithDivisionDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorWithDivisionDto
     */
    'foreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorWithDivisionDto
     */
    'initials'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorWithDivisionDto
     */
    'affiliations'?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AuthorWithDivisionDto
     */
    'divisions': Set<string>;
}
/**
 * 
 * @export
 * @interface BudgetStatsProjection
 */
export interface BudgetStatsProjection {
    /**
     * 
     * @type {number}
     * @memberof BudgetStatsProjection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetStatsProjection
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetStatsProjection
     */
    'sum': number;
}
/**
 * 
 * @export
 * @interface ClusterDtoChild
 */
export interface ClusterDtoChild {
    /**
     * 
     * @type {string}
     * @memberof ClusterDtoChild
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ClusterDtoChild
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface DiffDto
 */
export interface DiffDto {
    /**
     * 
     * @type {Array<PublicationListItemDto>}
     * @memberof DiffDto
     */
    'extra': Array<PublicationListItemDto>;
    /**
     * 
     * @type {Array<PublicationListItemDto>}
     * @memberof DiffDto
     */
    'notFound': Array<PublicationListItemDto>;
    /**
     * 
     * @type {Array<PublicationListItemDto>}
     * @memberof DiffDto
     */
    'allEntries': Array<PublicationListItemDto>;
}
/**
 * 
 * @export
 * @interface DivisionAdditionalStats
 */
export interface DivisionAdditionalStats {
    /**
     * 
     * @type {number}
     * @memberof DivisionAdditionalStats
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionAdditionalStats
     */
    'avgCitations': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionAdditionalStats
     */
    'hindex': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionAdditionalStats
     */
    'mrcr': number;
}
/**
 * 
 * @export
 * @interface DivisionDto
 */
export interface DivisionDto {
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof DivisionDto
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionDto
     */
    'endYear'?: number;
}
/**
 * 
 * @export
 * @interface DivisionDtoWithId
 */
export interface DivisionDtoWithId {
    /**
     * 
     * @type {number}
     * @memberof DivisionDtoWithId
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionDtoWithId
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionDtoWithId
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof DivisionDtoWithId
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionDtoWithId
     */
    'endYear'?: number;
}
/**
 * 
 * @export
 * @interface DivisionNameDto
 */
export interface DivisionNameDto {
    /**
     * 
     * @type {number}
     * @memberof DivisionNameDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionNameDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface DivisionStatsDto
 */
export interface DivisionStatsDto {
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionStatsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionStatsDto
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsDto
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsDto
     */
    'endYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DivisionStatsProjection
 */
export interface DivisionStatsProjection {
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsProjection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsProjection
     */
    'first': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsProjection
     */
    'last': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsProjection
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof DivisionStatsProjection
     */
    'second': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionStatsProjection
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface DivisionYearsDto
 */
export interface DivisionYearsDto {
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'divisionId': number;
    /**
     * 
     * @type {string}
     * @memberof DivisionYearsDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'endYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'startMon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'endMon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'startDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof DivisionYearsDto
     */
    'endDay'?: number;
}
/**
 * 
 * @export
 * @interface FiltersDto
 */
export interface FiltersDto {
    /**
     * 
     * @type {number}
     * @memberof FiltersDto
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof FiltersDto
     */
    'endYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    'useAcademicYear'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'division'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'paper'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'journal'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'position'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'rank'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    'types'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FiltersDto
     */
    'minRcr'?: number;
    /**
     * 
     * @type {number}
     * @memberof FiltersDto
     */
    'minNp'?: number;
    /**
     * 
     * @type {number}
     * @memberof FiltersDto
     */
    'minCitations'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    'researchArticle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    'hypothesis'?: boolean;
}
/**
 * 
 * @export
 * @interface FoundPersonDtoWithId
 */
export interface FoundPersonDtoWithId {
    /**
     * 
     * @type {number}
     * @memberof FoundPersonDtoWithId
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FoundPersonDtoWithId
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof FoundPersonDtoWithId
     */
    'foreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FoundPersonDtoWithId
     */
    'initials': string;
    /**
     * 
     * @type {string}
     * @memberof FoundPersonDtoWithId
     */
    'publications': string;
}
/**
 * 
 * @export
 * @interface FoundPersonIgnoreDto
 */
export interface FoundPersonIgnoreDto {
    /**
     * 
     * @type {number}
     * @memberof FoundPersonIgnoreDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ImportCatalystDto
 */
export interface ImportCatalystDto {
    /**
     * 
     * @type {string}
     * @memberof ImportCatalystDto
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCatalystDto
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof ImportCatalystDto
     */
    'division'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportCatalystDto
     */
    'filterFellows': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportCatalystDto
     */
    'useDivisions': boolean;
}
/**
 * 
 * @export
 * @interface InstitutionDto
 */
export interface InstitutionDto {
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    'inst': string;
}
/**
 * 
 * @export
 * @interface InstitutionStatsDto
 */
export interface InstitutionStatsDto {
    /**
     * 
     * @type {string}
     * @memberof InstitutionStatsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionStatsDto
     */
    'inst': string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionStatsDto
     */
    'role': string;
    /**
     * 
     * @type {number}
     * @memberof InstitutionStatsDto
     */
    'divisions': number;
    /**
     * 
     * @type {number}
     * @memberof InstitutionStatsDto
     */
    'people': number;
    /**
     * 
     * @type {number}
     * @memberof InstitutionStatsDto
     */
    'publications': number;
}
/**
 * 
 * @export
 * @interface JournalStatsProjection
 */
export interface JournalStatsProjection {
    /**
     * 
     * @type {number}
     * @memberof JournalStatsProjection
     */
    'first': number;
    /**
     * 
     * @type {number}
     * @memberof JournalStatsProjection
     */
    'last': number;
    /**
     * 
     * @type {number}
     * @memberof JournalStatsProjection
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof JournalStatsProjection
     */
    'second': number;
    /**
     * 
     * @type {string}
     * @memberof JournalStatsProjection
     */
    'journal': string;
}
/**
 * 
 * @export
 * @interface ManagerCreateDto
 */
export interface ManagerCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ManagerCreateDto
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerCreateDto
     */
    'role': string;
}
/**
 * 
 * @export
 * @interface ManagerDto
 */
export interface ManagerDto {
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDto
     */
    'role': string;
}
/**
 * 
 * @export
 * @interface ManualEntryDto
 */
export interface ManualEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ManualEntryDto
     */
    'pmid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualEntryDto
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface MeshDataDto
 */
export interface MeshDataDto {
    /**
     * 
     * @type {string}
     * @memberof MeshDataDto
     */
    'started'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshDataDto
     */
    'finished'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeshDataDto
     */
    'status'?: MeshDataDtoStatusEnum;
}

export const MeshDataDtoStatusEnum = {
    Queued: 'QUEUED',
    Started: 'STARTED',
    Downloaded: 'DOWNLOADED',
    Error: 'ERROR'
} as const;

export type MeshDataDtoStatusEnum = typeof MeshDataDtoStatusEnum[keyof typeof MeshDataDtoStatusEnum];

/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'academicRank': string;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'academicRankYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'academicRankMon'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'academicRankDay'?: number;
    /**
     * 
     * @type {Array<DivisionYearsDto>}
     * @memberof PersonDto
     */
    'divisions'?: Array<DivisionYearsDto>;
    /**
     * 
     * @type {Array<AlternativeNameDto>}
     * @memberof PersonDto
     */
    'alternativeNames'?: Array<AlternativeNameDto>;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'harvardCatalystPersonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'wrongAffiliations'?: string;
}
/**
 * 
 * @export
 * @interface PersonDtoWithId
 */
export interface PersonDtoWithId {
    /**
     * 
     * @type {number}
     * @memberof PersonDtoWithId
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PersonDtoWithId
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDtoWithId
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDtoWithId
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDtoWithId
     */
    'academicRank': string;
    /**
     * 
     * @type {number}
     * @memberof PersonDtoWithId
     */
    'academicRankYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonDtoWithId
     */
    'academicRankMon'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonDtoWithId
     */
    'academicRankDay'?: number;
    /**
     * 
     * @type {Array<DivisionYearsDto>}
     * @memberof PersonDtoWithId
     */
    'divisions'?: Array<DivisionYearsDto>;
    /**
     * 
     * @type {Array<AlternativeNameDto>}
     * @memberof PersonDtoWithId
     */
    'alternativeNames'?: Array<AlternativeNameDto>;
    /**
     * 
     * @type {number}
     * @memberof PersonDtoWithId
     */
    'harvardCatalystPersonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonDtoWithId
     */
    'wrongAffiliations'?: string;
}
/**
 * 
 * @export
 * @interface PersonIndexStatusProjection
 */
export interface PersonIndexStatusProjection {
    /**
     * 
     * @type {string}
     * @memberof PersonIndexStatusProjection
     */
    'lastRefreshed'?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'refreshStatus'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'count': number;
    /**
     * 
     * @type {boolean}
     * @memberof PersonIndexStatusProjection
     */
    'differentMiddleNamesFound'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'algoV1Count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'pubmedLastNameQueryCount': number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'harvardCatalystQueryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'selectedSnapshotCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'selectedSnapshotDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonIndexStatusProjection
     */
    'deletedCount'?: number;
}
/**
 * 
 * @export
 * @interface PersonNameDtoWithId
 */
export interface PersonNameDtoWithId {
    /**
     * 
     * @type {number}
     * @memberof PersonNameDtoWithId
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PersonNameDtoWithId
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonNameDtoWithId
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonNameDtoWithId
     */
    'middleName'?: string;
}
/**
 * 
 * @export
 * @interface PersonStatsDto
 */
export interface PersonStatsDto {
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PersonStatsDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonStatsDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonStatsDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonStatsDto
     */
    'academicRank': string;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'academicRankYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'academicRankMon'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'academicRankDay'?: number;
    /**
     * 
     * @type {Array<DivisionYearsDto>}
     * @memberof PersonStatsDto
     */
    'divisions'?: Array<DivisionYearsDto>;
    /**
     * 
     * @type {Array<AlternativeNameDto>}
     * @memberof PersonStatsDto
     */
    'alternativeNames'?: Array<AlternativeNameDto>;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'harvardCatalystPersonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonStatsDto
     */
    'wrongAffiliations'?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'pubmedLastNameQueryCount': number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'harvardCatalystQueryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'algoV1Count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PersonStatsDto
     */
    'differentMiddleNamesFound'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'deletedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'selectedSnapshotCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonStatsDto
     */
    'selectedSnapshotDeleted'?: number;
}
/**
 * 
 * @export
 * @interface PersonYearsStatsDto
 */
export interface PersonYearsStatsDto {
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PersonYearsStatsDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonYearsStatsDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonYearsStatsDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonYearsStatsDto
     */
    'academicRank'?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'first': number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'second': number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'last': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PersonYearsStatsDto
     */
    'years': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'weightedRCR'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'meanRCR'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'avgCitations'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonYearsStatsDto
     */
    'hindex'?: number;
}
/**
 * 
 * @export
 * @interface ProjectDetailsDto
 */
export interface ProjectDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'activity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'administeringIc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'applicationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'arraFunded'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'awardNoticeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'budgetStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'budgetEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'cfdaCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'coreProjectNum'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'edInstType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'opportunityNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'fullProjectNum'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'fundingIcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'fundingMechanism'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'fy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'icName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'nihSpendingCats'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgDept'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgDistrict'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgDuns'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgFips'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgIpfCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgState'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'orgZipcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'phr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'piIds'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'piNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'programOfficerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'projectStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'projectEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'projectTerms'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'projectTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'studySection'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'studySectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'subprojectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'supportYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'directCostAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'indirectCostAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'totalCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'totalCostSubProject'?: string;
    /**
     * 
     * @type {Array<ProjectPublicationDto>}
     * @memberof ProjectDetailsDto
     */
    'publications'?: Array<ProjectPublicationDto>;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'fullProjectNum': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'coreProjectNum': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'piNames': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'totalCost': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'subprojectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'totalCostSubProject': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'projectStart': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'projectEnd': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface ProjectFiltersDto
 */
export interface ProjectFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectFiltersDto
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectFiltersDto
     */
    'endYear'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectFiltersDto
     */
    'division'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectFiltersDto
     */
    'title'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectPIDto
 */
export interface ProjectPIDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectPIDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectPIDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPIDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPIDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPIDto
     */
    'academicRank': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectPIDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ProjectPublicationAuthorDto
 */
export interface ProjectPublicationAuthorDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationAuthorDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationAuthorDto
     */
    'foreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationAuthorDto
     */
    'initials'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectPublicationAuthorDto
     */
    'affiliations'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProjectPublicationAuthorDto
     */
    'personId'?: number;
}
/**
 * 
 * @export
 * @interface ProjectPublicationDto
 */
export interface ProjectPublicationDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationDto
     */
    'pmid': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationDto
     */
    'journal': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectPublicationDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectPublicationDto
     */
    'month'?: string;
    /**
     * 
     * @type {Array<ProjectPublicationAuthorDto>}
     * @memberof ProjectPublicationDto
     */
    'authorsList': Array<ProjectPublicationAuthorDto>;
}
/**
 * 
 * @export
 * @interface PublicationDto
 */
export interface PublicationDto {
    /**
     * 
     * @type {string}
     * @memberof PublicationDto
     */
    'pmid': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationDto
     */
    'journal': string;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof PublicationDto
     */
    'month'?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'authors': number;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<AuthorDto>}
     * @memberof PublicationDto
     */
    'authorsList': Array<AuthorDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'nihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'relativeCitationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'citations'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'publicationStatus'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationDto
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicationDto
     */
    'scoreDebug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationDto
     */
    'maybe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationDto
     */
    'researchPaper'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicationDto
     */
    'publicationTypes'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationDto
     */
    'pvaluePresence'?: boolean;
}
/**
 * 
 * @export
 * @interface PublicationListItemDto
 */
export interface PublicationListItemDto {
    /**
     * 
     * @type {string}
     * @memberof PublicationListItemDto
     */
    'pmid': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationListItemDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationListItemDto
     */
    'journal': string;
    /**
     * 
     * @type {number}
     * @memberof PublicationListItemDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof PublicationListItemDto
     */
    'month'?: string;
    /**
     * 
     * @type {Array<AuthorForListDto>}
     * @memberof PublicationListItemDto
     */
    'authorsList': Array<AuthorForListDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicationListItemDto
     */
    'nihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationListItemDto
     */
    'relativeCitationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationListItemDto
     */
    'citations'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationListItemDto
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicationListItemDto
     */
    'scoreDebug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationListItemDto
     */
    'maybe'?: boolean;
}
/**
 * 
 * @export
 * @interface PublicationWithAvailabilityDto
 */
export interface PublicationWithAvailabilityDto {
    /**
     * 
     * @type {PublicationListItemDto}
     * @memberof PublicationWithAvailabilityDto
     */
    'publication': PublicationListItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof PublicationWithAvailabilityDto
     */
    'alreadyExists': boolean;
}
/**
 * 
 * @export
 * @interface PublicationWithDivisionsDto
 */
export interface PublicationWithDivisionsDto {
    /**
     * 
     * @type {string}
     * @memberof PublicationWithDivisionsDto
     */
    'pmid': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationWithDivisionsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicationWithDivisionsDto
     */
    'journal': string;
    /**
     * 
     * @type {number}
     * @memberof PublicationWithDivisionsDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof PublicationWithDivisionsDto
     */
    'month'?: string;
    /**
     * 
     * @type {Array<AuthorWithDivisionDto>}
     * @memberof PublicationWithDivisionsDto
     */
    'authorsList': Array<AuthorWithDivisionDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicationWithDivisionsDto
     */
    'nihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationWithDivisionsDto
     */
    'relativeCitationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicationWithDivisionsDto
     */
    'citations'?: number;
}
/**
 * 
 * @export
 * @interface PubmedData
 */
export interface PubmedData {
    /**
     * 
     * @type {string}
     * @memberof PubmedData
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof PubmedData
     */
    'started'?: string;
    /**
     * 
     * @type {string}
     * @memberof PubmedData
     */
    'finished'?: string;
    /**
     * 
     * @type {string}
     * @memberof PubmedData
     */
    'status': PubmedDataStatusEnum;
}

export const PubmedDataStatusEnum = {
    Queued: 'QUEUED',
    Started: 'STARTED',
    Downloaded: 'DOWNLOADED',
    Error: 'ERROR'
} as const;

export type PubmedDataStatusEnum = typeof PubmedDataStatusEnum[keyof typeof PubmedDataStatusEnum];

/**
 * 
 * @export
 * @interface ReportDetailsDto
 */
export interface ReportDetailsDto {
    /**
     * 
     * @type {ReportPublicationDto}
     * @memberof ReportDetailsDto
     */
    'publication'?: ReportPublicationDto;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'newCitations'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'newNihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'newRelativeCitationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'oldCitations'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'oldNihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDetailsDto
     */
    'oldRelativeCitationRatio'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDetailsDto
     */
    'citationThreshold'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDetailsDto
     */
    'new': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDetailsDto
     */
    'rcrThreshold'?: boolean;
}
/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    'timeframe': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    'newPublications': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    'highlyCited': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    'highRCR': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    'to': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDto
     */
    'current': boolean;
}
/**
 * 
 * @export
 * @interface ReportPublicationDto
 */
export interface ReportPublicationDto {
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationDto
     */
    'pmid': string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationDto
     */
    'journal': string;
    /**
     * 
     * @type {number}
     * @memberof ReportPublicationDto
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPublicationDto
     */
    'month'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportPublicationDto
     */
    'authors': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPublicationDto
     */
    'positions'?: Array<number>;
    /**
     * 
     * @type {Array<AuthorDto>}
     * @memberof ReportPublicationDto
     */
    'authorsList': Array<AuthorDto>;
    /**
     * 
     * @type {number}
     * @memberof ReportPublicationDto
     */
    'nihPercentile'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportPublicationDto
     */
    'relativeCitationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportPublicationDto
     */
    'citations'?: number;
}
/**
 * 
 * @export
 * @interface ReporterDataDto
 */
export interface ReporterDataDto {
    /**
     * 
     * @type {string}
     * @memberof ReporterDataDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReporterDataDto
     */
    'started'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReporterDataDto
     */
    'finished'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReporterDataDto
     */
    'status'?: ReporterDataDtoStatusEnum;
}

export const ReporterDataDtoStatusEnum = {
    Queued: 'QUEUED',
    Started: 'STARTED',
    Downloaded: 'DOWNLOADED',
    Error: 'ERROR'
} as const;

export type ReporterDataDtoStatusEnum = typeof ReporterDataDtoStatusEnum[keyof typeof ReporterDataDtoStatusEnum];

/**
 * 
 * @export
 * @interface SettingsDto
 */
export interface SettingsDto {
    /**
     * 
     * @type {number}
     * @memberof SettingsDto
     */
    'startYear': number;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    'logo': string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    'citationsFeature': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    'projectsFeature': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    'foundPersonsFeature': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    'authSuffix'?: string;
}
/**
 * 
 * @export
 * @interface SettingsIntDto
 */
export interface SettingsIntDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsIntDto
     */
    'orgNames': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsIntDto
     */
    'keywords': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SettingsIntDto
     */
    'startYear': number;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntDto
     */
    'authSuffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntDto
     */
    'logo': string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsIntDto
     */
    'citationsFeature': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsIntDto
     */
    'projectsFeature': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsIntDto
     */
    'exactOrgNames': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsIntDto
     */
    'exactOrgNamesIgnore': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntDto
     */
    'foundLastRefresh'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsIntDto
     */
    'forceRefreshFound': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsIntDto
     */
    'foundPersonsFeature': boolean;
}
/**
 * 
 * @export
 * @interface SnapshotCreateDto
 */
export interface SnapshotCreateDto {
    /**
     * 
     * @type {string}
     * @memberof SnapshotCreateDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SnapshotDto
 */
export interface SnapshotDto {
    /**
     * 
     * @type {number}
     * @memberof SnapshotDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface StatsProjection
 */
export interface StatsProjection {
    /**
     * 
     * @type {number}
     * @memberof StatsProjection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StatsProjection
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface StatsProjectionWithPositions
 */
export interface StatsProjectionWithPositions {
    /**
     * 
     * @type {number}
     * @memberof StatsProjectionWithPositions
     */
    'first': number;
    /**
     * 
     * @type {number}
     * @memberof StatsProjectionWithPositions
     */
    'last': number;
    /**
     * 
     * @type {number}
     * @memberof StatsProjectionWithPositions
     */
    'second': number;
    /**
     * 
     * @type {number}
     * @memberof StatsProjectionWithPositions
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StatsProjectionWithPositions
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface VerifyResultDto
 */
export interface VerifyResultDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyResultDto
     */
    'resultHtml': string;
    /**
     * 
     * @type {Array<PublicationWithAvailabilityDto>}
     * @memberof VerifyResultDto
     */
    'publications': Array<PublicationWithAvailabilityDto>;
}

/**
 * MainApi - axios parameter creator
 * @export
 */
export const MainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstitution: async (inst: string, settingsIntDto: SettingsIntDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inst' is not null or undefined
            assertParamExists('addInstitution', 'inst', inst)
            // verify required parameter 'settingsIntDto' is not null or undefined
            assertParamExists('addInstitution', 'settingsIntDto', settingsIntDto)
            const localVarPath = `/api/org/institution/{inst}`
                .replace(`{${"inst"}}`, encodeURIComponent(String(inst)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsIntDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManual: async (id: number, type: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addManual', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('addManual', 'type', type)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addManual', 'requestBody', requestBody)
            const localVarPath = `/api/manage/manual/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManagerRole: async (id: string, role: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeManagerRole', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('changeManagerRole', 'role', role)
            const localVarPath = `/api/manage/managerRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDivision: async (divisionDto: DivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'divisionDto' is not null or undefined
            assertParamExists('createDivision', 'divisionDto', divisionDto)
            const localVarPath = `/api/manage/division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(divisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagerCreateDto} managerCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager: async (managerCreateDto: ManagerCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managerCreateDto' is not null or undefined
            assertParamExists('createManager', 'managerCreateDto', managerCreateDto)
            const localVarPath = `/api/manage/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (personDto: PersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personDto' is not null or undefined
            assertParamExists('createPerson', 'personDto', personDto)
            const localVarPath = `/api/manage/person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SnapshotCreateDto} snapshotCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnapshot: async (snapshotCreateDto: SnapshotCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotCreateDto' is not null or undefined
            assertParamExists('createSnapshot', 'snapshotCreateDto', snapshotCreateDto)
            const localVarPath = `/api/admin/snapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snapshotCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delInstitution: async (inst: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inst' is not null or undefined
            assertParamExists('delInstitution', 'inst', inst)
            const localVarPath = `/api/org/institution/{inst}`
                .replace(`{${"inst"}}`, encodeURIComponent(String(inst)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllDivisions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllPersons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivision: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDivision', 'id', id)
            const localVarPath = `/api/manage/division/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteManager', 'id', id)
            const localVarPath = `/api/manage/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            const localVarPath = `/api/manage/person/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionDivision: async (filtersDto: FiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('distributionDivision', 'filtersDto', filtersDto)
            const localVarPath = `/api/analytics/division`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionJournal: async (filtersDto: FiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('distributionJournal', 'filtersDto', filtersDto)
            const localVarPath = `/api/analytics/journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionYear: async (filtersDto: FiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('distributionYear', 'filtersDto', filtersDto)
            const localVarPath = `/api/analytics/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionAdditionalStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/divisionAdditionalStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAll: async (force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('downloadAll', 'force', force)
            const localVarPath = `/api/admin/downloadAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMesh: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/downloadMesh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} currentYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReporter: async (currentYear: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentYear' is not null or undefined
            assertParamExists('downloadReporter', 'currentYear', currentYear)
            const localVarPath = `/api/admin/downloadReporter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentYear !== undefined) {
                localVarQueryParameter['currentYear'] = currentYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUpdates: async (force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/downloadUpdates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {FiltersDto} filtersDto 
         * @param {number} [personId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportData: async (type: string, filtersDto: FiltersDto, personId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('exportData', 'type', type)
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('exportData', 'filtersDto', filtersDto)
            const localVarPath = `/api/report/exportData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (personId !== undefined) {
                localVarQueryParameter['personId'] = personId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReportDetails: async (period: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('exportReportDetails', 'period', period)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportReportDetails', 'from', from)
            const localVarPath = `/api/report/exportReportDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundPersons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/foundPersons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ver 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgoDiff: async (ver: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ver' is not null or undefined
            assertParamExists('getAlgoDiff', 'ver', ver)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAlgoDiff', 'id', id)
            const localVarPath = `/api/admin/diff/algo/{ver}/{id}`
                .replace(`{${"ver"}}`, encodeURIComponent(String(ver)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInstIds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/inst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/publications/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivision: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDivision', 'id', id)
            const localVarPath = `/api/manage/division/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarvardDiff: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHarvardDiff', 'id', id)
            const localVarPath = `/api/admin/diff/harvard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeshData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/getMeshData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinMaxYears: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMinMaxYears', 'id', id)
            const localVarPath = `/api/analytics/publications/minMaxYear/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/api/manage/person/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pmid 
         * @param {number} idx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonByPmidAndPosition: async (pmid: string, idx: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmid' is not null or undefined
            assertParamExists('getPersonByPmidAndPosition', 'pmid', pmid)
            // verify required parameter 'idx' is not null or undefined
            assertParamExists('getPersonByPmidAndPosition', 'idx', idx)
            const localVarPath = `/api/analytics/personId/{pmid}/{idx}`
                .replace(`{${"pmid"}}`, encodeURIComponent(String(pmid)))
                .replace(`{${"idx"}}`, encodeURIComponent(String(idx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPubmedData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/getData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPubmedDiff: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPubmedDiff', 'id', id)
            const localVarPath = `/api/admin/diff/pubmed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedCount: async (id: number, full: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRemovedCount', 'id', id)
            // verify required parameter 'full' is not null or undefined
            assertParamExists('getRemovedCount', 'full', full)
            const localVarPath = `/api/manage/removed/{id}/count`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetails: async (period: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('getReportDetails', 'period', period)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getReportDetails', 'from', from)
            const localVarPath = `/api/report/report/{period}/{from}`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)))
                .replace(`{${"from"}}`, encodeURIComponent(String(from)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporterData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/reporterData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (period: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('getReports', 'period', period)
            const localVarPath = `/api/report/reports/{period}`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsInt: async (inst: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inst' is not null or undefined
            assertParamExists('getSettingsInt', 'inst', inst)
            const localVarPath = `/api/org/settingsInt/{inst}`
                .replace(`{${"inst"}}`, encodeURIComponent(String(inst)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} snapshotId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotDiff: async (snapshotId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('getSnapshotDiff', 'snapshotId', snapshotId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSnapshotDiff', 'id', id)
            const localVarPath = `/api/admin/diff/snapshot/{snapshotId}/{id}`
                .replace(`{${"snapshotId"}}`, encodeURIComponent(String(snapshotId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshots: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/snapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hcImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/massimport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FoundPersonIgnoreDto} foundPersonIgnoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ignoreFound: async (foundPersonIgnoreDto: FoundPersonIgnoreDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'foundPersonIgnoreDto' is not null or undefined
            assertParamExists('ignoreFound', 'foundPersonIgnoreDto', foundPersonIgnoreDto)
            const localVarPath = `/api/manage/ignoreFound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(foundPersonIgnoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportCatalystDto} importCatalystDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCatalyst: async (importCatalystDto: ImportCatalystDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importCatalystDto' is not null or undefined
            assertParamExists('importCatalyst', 'importCatalystDto', importCatalystDto)
            const localVarPath = `/api/admin/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importCatalystDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDivisionNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/divisionNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDivisions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/listDivisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExcluded: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listExcluded', 'id', id)
            const localVarPath = `/api/analytics/publications/listExcluded/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutionNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/institutionNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/institution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManualEntries: async (id: number, type: string, full: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listManualEntries', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listManualEntries', 'type', type)
            // verify required parameter 'full' is not null or undefined
            assertParamExists('listManualEntries', 'full', full)
            const localVarPath = `/api/manage/manual/{type}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [divisionId] 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersons: async (divisionId?: number, snapshotId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (divisionId !== undefined) {
                localVarQueryParameter['divisionId'] = divisionId;
            }

            if (snapshotId !== undefined) {
                localVarQueryParameter['snapshotId'] = snapshotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersonsByYearAndDivision: async (filtersDto: FiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('listPersonsByYearAndDivision', 'filtersDto', filtersDto)
            const localVarPath = `/api/analytics/people`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} personId 
         * @param {boolean} showIgnored 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectForPerson: async (personId: number, showIgnored: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('listProjectForPerson', 'personId', personId)
            // verify required parameter 'showIgnored' is not null or undefined
            assertParamExists('listProjectForPerson', 'showIgnored', showIgnored)
            const localVarPath = `/api/analytics/listProjectsForPerson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (personId !== undefined) {
                localVarQueryParameter['personId'] = personId;
            }

            if (showIgnored !== undefined) {
                localVarQueryParameter['showIgnored'] = showIgnored;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectPIByYearAndDivision: async (projectFiltersDto: ProjectFiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectFiltersDto' is not null or undefined
            assertParamExists('listProjectPIByYearAndDivision', 'projectFiltersDto', projectFiltersDto)
            const localVarPath = `/api/analytics/projectsPIs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFiltersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects: async (projectFiltersDto: ProjectFiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectFiltersDto' is not null or undefined
            assertParamExists('listProjects', 'projectFiltersDto', projectFiltersDto)
            const localVarPath = `/api/analytics/listProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFiltersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublications: async (id: number, page: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listPublications', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listPublications', 'page', page)
            const localVarPath = `/api/analytics/publications/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicationsByYearAndDivision: async (filtersDto: FiltersDto, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filtersDto' is not null or undefined
            assertParamExists('listPublicationsByYearAndDivision', 'filtersDto', filtersDto)
            const localVarPath = `/api/analytics/publicationsByYearAndDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filtersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicationsMultiDivision: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/publicationsMulti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRemovedEntries: async (id: number, full: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listRemovedEntries', 'id', id)
            // verify required parameter 'full' is not null or undefined
            assertParamExists('listRemovedEntries', 'full', full)
            const localVarPath = `/api/manage/removed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/loadCSV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personIndexStatus: async (snapshotId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manage/personIndexStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (snapshotId !== undefined) {
                localVarQueryParameter['snapshotId'] = snapshotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDetails: async (fullProjectNum: string, year: number, subprojectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fullProjectNum' is not null or undefined
            assertParamExists('projectDetails', 'fullProjectNum', fullProjectNum)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('projectDetails', 'year', year)
            // verify required parameter 'subprojectId' is not null or undefined
            assertParamExists('projectDetails', 'subprojectId', subprojectId)
            const localVarPath = `/api/analytics/projectDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fullProjectNum !== undefined) {
                localVarQueryParameter['fullProjectNum'] = fullProjectNum;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (subprojectId !== undefined) {
                localVarQueryParameter['subprojectId'] = subprojectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} personId 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {number} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectStatus: async (personId: number, fullProjectNum: string, year: number, subprojectId: string, status: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('projectStatus', 'personId', personId)
            // verify required parameter 'fullProjectNum' is not null or undefined
            assertParamExists('projectStatus', 'fullProjectNum', fullProjectNum)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('projectStatus', 'year', year)
            // verify required parameter 'subprojectId' is not null or undefined
            assertParamExists('projectStatus', 'subprojectId', subprojectId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('projectStatus', 'status', status)
            const localVarPath = `/api/analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (personId !== undefined) {
                localVarQueryParameter['personId'] = personId;
            }

            if (fullProjectNum !== undefined) {
                localVarQueryParameter['fullProjectNum'] = fullProjectNum;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (subprojectId !== undefined) {
                localVarQueryParameter['subprojectId'] = subprojectId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsBudgetYear: async (projectFiltersDto: ProjectFiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectFiltersDto' is not null or undefined
            assertParamExists('projectsBudgetYear', 'projectFiltersDto', projectFiltersDto)
            const localVarPath = `/api/analytics/projectsBudget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFiltersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsDistributionYear: async (projectFiltersDto: ProjectFiltersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectFiltersDto' is not null or undefined
            assertParamExists('projectsDistributionYear', 'projectFiltersDto', projectFiltersDto)
            const localVarPath = `/api/analytics/projectsYears`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFiltersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reindexAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/reindexAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reindexPerson: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reindexPerson', 'id', id)
            const localVarPath = `/api/manage/reindex/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntries: async (id: number, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEntries', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('removeEntries', 'requestBody', requestBody)
            const localVarPath = `/api/manage/removed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromManual: async (id: number, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFromManual', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('removeFromManual', 'requestBody', requestBody)
            const localVarPath = `/api/manage/manual/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSnapshot: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSnapshot', 'id', id)
            const localVarPath = `/api/admin/snapshot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivision: async (id: number, divisionDto: DivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDivision', 'id', id)
            // verify required parameter 'divisionDto' is not null or undefined
            assertParamExists('updateDivision', 'divisionDto', divisionDto)
            const localVarPath = `/api/manage/division/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(divisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: number, personDto: PersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            // verify required parameter 'personDto' is not null or undefined
            assertParamExists('updatePerson', 'personDto', personDto)
            const localVarPath = `/api/manage/person/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (inst: string, settingsIntDto: SettingsIntDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inst' is not null or undefined
            assertParamExists('updateSettings', 'inst', inst)
            // verify required parameter 'settingsIntDto' is not null or undefined
            assertParamExists('updateSettings', 'settingsIntDto', settingsIntDto)
            const localVarPath = `/api/org/settings/{inst}`
                .replace(`{${"inst"}}`, encodeURIComponent(String(inst)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsIntDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCSV: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadCSV', 'file', file)
            const localVarPath = `/api/manage/uploadCSV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyList: async (id: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifyList', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('verifyList', 'body', body)
            const localVarPath = `/api/manage/verify/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainApi - functional programming interface
 * @export
 */
export const MainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInstitution(inst: string, settingsIntDto: SettingsIntDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInstitution(inst, settingsIntDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addManual(id: number, type: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addManual(id, type, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeManagerRole(id: string, role: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeManagerRole(id, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDivision(divisionDto: DivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDivision(divisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManagerCreateDto} managerCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManager(managerCreateDto: ManagerCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManager(managerCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(personDto: PersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SnapshotCreateDto} snapshotCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnapshot(snapshotCreateDto: SnapshotCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSnapshot(snapshotCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delInstitution(inst: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delInstitution(inst, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllDivisions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllDivisions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllPersons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllPersons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDivision(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDivision(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManager(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManager(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributionDivision(filtersDto: FiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DivisionStatsProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributionDivision(filtersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributionJournal(filtersDto: FiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalStatsProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributionJournal(filtersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributionYear(filtersDto: FiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatsProjectionWithPositions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributionYear(filtersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionAdditionalStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DivisionAdditionalStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionAdditionalStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAll(force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAll(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMesh(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMesh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} currentYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReporter(currentYear: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReporter(currentYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUpdates(force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUpdates(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {FiltersDto} filtersDto 
         * @param {number} [personId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportData(type: string, filtersDto: FiltersDto, personId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportData(type, filtersDto, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportReportDetails(period: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportReportDetails(period, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foundPersons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoundPersonDtoWithId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foundPersons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ver 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlgoDiff(ver: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiffDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlgoDiff(ver, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInstIds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInstIds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClusterDtoChild>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivision(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivision(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHarvardDiff(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiffDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHarvardDiff(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeshData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeshDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeshData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinMaxYears(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinMaxYears(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pmid 
         * @param {number} idx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonByPmidAndPosition(pmid: string, idx: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonNameDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonByPmidAndPosition(pmid, idx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPubmedData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PubmedData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPubmedData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPubmedDiff(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiffDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPubmedDiff(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemovedCount(id: number, full: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemovedCount(id, full, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportDetails(period: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportDetails(period, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporterData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReporterDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporterData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(period: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsInt(inst: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsIntDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsInt(inst, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} snapshotId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotDiff(snapshotId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiffDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotDiff(snapshotId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshots(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SnapshotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hcImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hcImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FoundPersonIgnoreDto} foundPersonIgnoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ignoreFound(foundPersonIgnoreDto: FoundPersonIgnoreDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ignoreFound(foundPersonIgnoreDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportCatalystDto} importCatalystDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCatalyst(importCatalystDto: ImportCatalystDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCatalyst(importCatalystDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDivisionNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DivisionNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDivisionNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDivisions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DivisionStatsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDivisions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExcluded(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExcluded(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstitutionNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstitutionNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstitutions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionStatsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstitutions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManualEntries(id: number, type: string, full: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManualEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManualEntries(id, type, full, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [divisionId] 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPersons(divisionId?: number, snapshotId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonStatsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPersons(divisionId, snapshotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPersonsByYearAndDivision(filtersDto: FiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonYearsStatsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPersonsByYearAndDivision(filtersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} personId 
         * @param {boolean} showIgnored 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectForPerson(personId: number, showIgnored: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectForPerson(personId, showIgnored, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectPIByYearAndDivision(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectPIDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectPIByYearAndDivision(projectFiltersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjects(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjects(projectFiltersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublications(id: number, page: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublications(id, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicationsByYearAndDivision(filtersDto: FiltersDto, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicationListItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicationsByYearAndDivision(filtersDto, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicationsMultiDivision(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicationWithDivisionsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicationsMultiDivision(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRemovedEntries(id: number, full: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicationListItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRemovedEntries(id, full, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personIndexStatus(snapshotId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonIndexStatusProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personIndexStatus(snapshotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDetails(fullProjectNum: string, year: number, subprojectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDetails(fullProjectNum, year, subprojectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} personId 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {number} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectStatus(personId: number, fullProjectNum: string, year: number, subprojectId: string, status: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectStatus(personId, fullProjectNum, year, subprojectId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsBudgetYear(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BudgetStatsProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsBudgetYear(projectFiltersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsDistributionYear(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatsProjection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsDistributionYear(projectFiltersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reindexAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reindexAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reindexPerson(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reindexPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEntries(id: number, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntries(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromManual(id: number, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromManual(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSnapshot(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSnapshot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDivision(id: number, divisionDto: DivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDivision(id, divisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: number, personDto: PersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDtoWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(inst: string, settingsIntDto: SettingsIntDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsIntDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(inst, settingsIntDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCSV(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCSV(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyList(id: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyList(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainApi - factory interface
 * @export
 */
export const MainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstitution(inst: string, settingsIntDto: SettingsIntDto, options?: any): AxiosPromise<void> {
            return localVarFp.addInstitution(inst, settingsIntDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManual(id: number, type: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.addManual(id, type, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManagerRole(id: string, role: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeManagerRole(id, role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDivision(divisionDto: DivisionDto, options?: any): AxiosPromise<DivisionDtoWithId> {
            return localVarFp.createDivision(divisionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagerCreateDto} managerCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager(managerCreateDto: ManagerCreateDto, options?: any): AxiosPromise<ManagerDto> {
            return localVarFp.createManager(managerCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(personDto: PersonDto, options?: any): AxiosPromise<PersonDtoWithId> {
            return localVarFp.createPerson(personDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SnapshotCreateDto} snapshotCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnapshot(snapshotCreateDto: SnapshotCreateDto, options?: any): AxiosPromise<SnapshotDto> {
            return localVarFp.createSnapshot(snapshotCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delInstitution(inst: string, options?: any): AxiosPromise<void> {
            return localVarFp.delInstitution(inst, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllDivisions(options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllDivisions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllPersons(options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllPersons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivision(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDivision(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManager(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionDivision(filtersDto: FiltersDto, options?: any): AxiosPromise<Array<DivisionStatsProjection>> {
            return localVarFp.distributionDivision(filtersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionJournal(filtersDto: FiltersDto, options?: any): AxiosPromise<Array<JournalStatsProjection>> {
            return localVarFp.distributionJournal(filtersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributionYear(filtersDto: FiltersDto, options?: any): AxiosPromise<Array<StatsProjectionWithPositions>> {
            return localVarFp.distributionYear(filtersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionAdditionalStats(options?: any): AxiosPromise<Array<DivisionAdditionalStats>> {
            return localVarFp.divisionAdditionalStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAll(force: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.downloadAll(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMesh(options?: any): AxiosPromise<void> {
            return localVarFp.downloadMesh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} currentYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReporter(currentYear: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.downloadReporter(currentYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUpdates(force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.downloadUpdates(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {FiltersDto} filtersDto 
         * @param {number} [personId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportData(type: string, filtersDto: FiltersDto, personId?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportData(type, filtersDto, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReportDetails(period: string, from: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportReportDetails(period, from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundPersons(options?: any): AxiosPromise<Array<FoundPersonDtoWithId>> {
            return localVarFp.foundPersons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ver 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgoDiff(ver: string, id: number, options?: any): AxiosPromise<DiffDto> {
            return localVarFp.getAlgoDiff(ver, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInstIds(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllInstIds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters(options?: any): AxiosPromise<Array<ClusterDtoChild>> {
            return localVarFp.getClusters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivision(id: number, options?: any): AxiosPromise<DivisionDtoWithId> {
            return localVarFp.getDivision(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarvardDiff(id: number, options?: any): AxiosPromise<DiffDto> {
            return localVarFp.getHarvardDiff(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeshData(options?: any): AxiosPromise<MeshDataDto> {
            return localVarFp.getMeshData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinMaxYears(id: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getMinMaxYears(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: number, options?: any): AxiosPromise<PersonDtoWithId> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pmid 
         * @param {number} idx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonByPmidAndPosition(pmid: string, idx: number, options?: any): AxiosPromise<PersonNameDtoWithId> {
            return localVarFp.getPersonByPmidAndPosition(pmid, idx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPubmedData(options?: any): AxiosPromise<Array<PubmedData>> {
            return localVarFp.getPubmedData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPubmedDiff(id: number, options?: any): AxiosPromise<DiffDto> {
            return localVarFp.getPubmedDiff(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedCount(id: number, full: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.getRemovedCount(id, full, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} period 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetails(period: string, from: string, options?: any): AxiosPromise<Array<ReportDetailsDto>> {
            return localVarFp.getReportDetails(period, from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporterData(options?: any): AxiosPromise<Array<ReporterDataDto>> {
            return localVarFp.getReporterData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(period: string, options?: any): AxiosPromise<Array<ReportDto>> {
            return localVarFp.getReports(period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<SettingsDto> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsInt(inst: string, options?: any): AxiosPromise<SettingsIntDto> {
            return localVarFp.getSettingsInt(inst, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} snapshotId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotDiff(snapshotId: number, id: number, options?: any): AxiosPromise<DiffDto> {
            return localVarFp.getSnapshotDiff(snapshotId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshots(options?: any): AxiosPromise<Array<SnapshotDto>> {
            return localVarFp.getSnapshots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hcImport(options?: any): AxiosPromise<void> {
            return localVarFp.hcImport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FoundPersonIgnoreDto} foundPersonIgnoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ignoreFound(foundPersonIgnoreDto: FoundPersonIgnoreDto, options?: any): AxiosPromise<void> {
            return localVarFp.ignoreFound(foundPersonIgnoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportCatalystDto} importCatalystDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCatalyst(importCatalystDto: ImportCatalystDto, options?: any): AxiosPromise<void> {
            return localVarFp.importCatalyst(importCatalystDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDivisionNames(options?: any): AxiosPromise<Array<DivisionNameDto>> {
            return localVarFp.listDivisionNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDivisions(options?: any): AxiosPromise<Array<DivisionStatsDto>> {
            return localVarFp.listDivisions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExcluded(id: number, options?: any): AxiosPromise<Array<PublicationDto>> {
            return localVarFp.listExcluded(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutionNames(options?: any): AxiosPromise<Array<InstitutionDto>> {
            return localVarFp.listInstitutionNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutions(options?: any): AxiosPromise<Array<InstitutionStatsDto>> {
            return localVarFp.listInstitutions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagers(options?: any): AxiosPromise<Array<ManagerDto>> {
            return localVarFp.listManagers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} type 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManualEntries(id: number, type: string, full: boolean, options?: any): AxiosPromise<Array<ManualEntryDto>> {
            return localVarFp.listManualEntries(id, type, full, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [divisionId] 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersons(divisionId?: number, snapshotId?: number, options?: any): AxiosPromise<Array<PersonStatsDto>> {
            return localVarFp.listPersons(divisionId, snapshotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersonsByYearAndDivision(filtersDto: FiltersDto, options?: any): AxiosPromise<Array<PersonYearsStatsDto>> {
            return localVarFp.listPersonsByYearAndDivision(filtersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} personId 
         * @param {boolean} showIgnored 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectForPerson(personId: number, showIgnored: boolean, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.listProjectForPerson(personId, showIgnored, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectPIByYearAndDivision(projectFiltersDto: ProjectFiltersDto, options?: any): AxiosPromise<Array<ProjectPIDto>> {
            return localVarFp.listProjectPIByYearAndDivision(projectFiltersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(projectFiltersDto: ProjectFiltersDto, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.listProjects(projectFiltersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublications(id: number, page: number, pageSize?: number, options?: any): AxiosPromise<Array<PublicationDto>> {
            return localVarFp.listPublications(id, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FiltersDto} filtersDto 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicationsByYearAndDivision(filtersDto: FiltersDto, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<PublicationListItemDto>> {
            return localVarFp.listPublicationsByYearAndDivision(filtersDto, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicationsMultiDivision(options?: any): AxiosPromise<Array<PublicationWithDivisionsDto>> {
            return localVarFp.listPublicationsMultiDivision(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} full 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRemovedEntries(id: number, full: boolean, options?: any): AxiosPromise<Array<PublicationListItemDto>> {
            return localVarFp.listRemovedEntries(id, full, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCSV(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.loadCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [snapshotId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personIndexStatus(snapshotId?: number, options?: any): AxiosPromise<Array<PersonIndexStatusProjection>> {
            return localVarFp.personIndexStatus(snapshotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDetails(fullProjectNum: string, year: number, subprojectId: string, options?: any): AxiosPromise<ProjectDetailsDto> {
            return localVarFp.projectDetails(fullProjectNum, year, subprojectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} personId 
         * @param {string} fullProjectNum 
         * @param {number} year 
         * @param {string} subprojectId 
         * @param {number} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectStatus(personId: number, fullProjectNum: string, year: number, subprojectId: string, status: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectStatus(personId, fullProjectNum, year, subprojectId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsBudgetYear(projectFiltersDto: ProjectFiltersDto, options?: any): AxiosPromise<Array<BudgetStatsProjection>> {
            return localVarFp.projectsBudgetYear(projectFiltersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectFiltersDto} projectFiltersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsDistributionYear(projectFiltersDto: ProjectFiltersDto, options?: any): AxiosPromise<Array<StatsProjection>> {
            return localVarFp.projectsDistributionYear(projectFiltersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reindexAll(options?: any): AxiosPromise<void> {
            return localVarFp.reindexAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reindexPerson(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.reindexPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntries(id: number, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.removeEntries(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromManual(id: number, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.removeFromManual(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSnapshot(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeSnapshot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DivisionDto} divisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivision(id: number, divisionDto: DivisionDto, options?: any): AxiosPromise<DivisionDtoWithId> {
            return localVarFp.updateDivision(id, divisionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PersonDto} personDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: number, personDto: PersonDto, options?: any): AxiosPromise<PersonDtoWithId> {
            return localVarFp.updatePerson(id, personDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inst 
         * @param {SettingsIntDto} settingsIntDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(inst: string, settingsIntDto: SettingsIntDto, options?: any): AxiosPromise<SettingsIntDto> {
            return localVarFp.updateSettings(inst, settingsIntDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCSV(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadCSV(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyList(id: number, body: string, options?: any): AxiosPromise<VerifyResultDto> {
            return localVarFp.verifyList(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {
    /**
     * 
     * @param {string} inst 
     * @param {SettingsIntDto} settingsIntDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public addInstitution(inst: string, settingsIntDto: SettingsIntDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).addInstitution(inst, settingsIntDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} type 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public addManual(id: number, type: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).addManual(id, type, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public changeManagerRole(id: string, role: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).changeManagerRole(id, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DivisionDto} divisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public createDivision(divisionDto: DivisionDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).createDivision(divisionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagerCreateDto} managerCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public createManager(managerCreateDto: ManagerCreateDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).createManager(managerCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonDto} personDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public createPerson(personDto: PersonDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).createPerson(personDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SnapshotCreateDto} snapshotCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public createSnapshot(snapshotCreateDto: SnapshotCreateDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).createSnapshot(snapshotCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inst 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public delInstitution(inst: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).delInstitution(inst, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deleteAllDivisions(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).deleteAllDivisions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deleteAllPersons(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).deleteAllPersons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deleteDivision(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).deleteDivision(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deleteManager(id: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).deleteManager(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deletePerson(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).deletePerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FiltersDto} filtersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public distributionDivision(filtersDto: FiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).distributionDivision(filtersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FiltersDto} filtersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public distributionJournal(filtersDto: FiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).distributionJournal(filtersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FiltersDto} filtersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public distributionYear(filtersDto: FiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).distributionYear(filtersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public divisionAdditionalStats(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).divisionAdditionalStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public downloadAll(force: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).downloadAll(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public downloadMesh(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).downloadMesh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} currentYear 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public downloadReporter(currentYear: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).downloadReporter(currentYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public downloadUpdates(force?: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).downloadUpdates(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {FiltersDto} filtersDto 
     * @param {number} [personId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public exportData(type: string, filtersDto: FiltersDto, personId?: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).exportData(type, filtersDto, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} period 
     * @param {string} from 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public exportReportDetails(period: string, from: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).exportReportDetails(period, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public foundPersons(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).foundPersons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ver 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getAlgoDiff(ver: string, id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getAlgoDiff(ver, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getAllInstIds(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getAllInstIds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getClusters(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getClusters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getDivision(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getDivision(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getHarvardDiff(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getHarvardDiff(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getMeshData(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getMeshData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getMinMaxYears(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getMinMaxYears(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getPerson(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pmid 
     * @param {number} idx 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getPersonByPmidAndPosition(pmid: string, idx: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getPersonByPmidAndPosition(pmid, idx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getPubmedData(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getPubmedData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getPubmedDiff(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getPubmedDiff(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} full 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getRemovedCount(id: number, full: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getRemovedCount(id, full, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} period 
     * @param {string} from 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getReportDetails(period: string, from: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getReportDetails(period, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getReporterData(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getReporterData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getReports(period: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getReports(period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inst 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getSettingsInt(inst: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getSettingsInt(inst, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} snapshotId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getSnapshotDiff(snapshotId: number, id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getSnapshotDiff(snapshotId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getSnapshots(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getSnapshots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public hcImport(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).hcImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FoundPersonIgnoreDto} foundPersonIgnoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public ignoreFound(foundPersonIgnoreDto: FoundPersonIgnoreDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).ignoreFound(foundPersonIgnoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportCatalystDto} importCatalystDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public importCatalyst(importCatalystDto: ImportCatalystDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).importCatalyst(importCatalystDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listDivisionNames(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listDivisionNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listDivisions(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listDivisions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listExcluded(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listExcluded(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listInstitutionNames(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listInstitutionNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listInstitutions(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listInstitutions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listManagers(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listManagers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} type 
     * @param {boolean} full 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listManualEntries(id: number, type: string, full: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listManualEntries(id, type, full, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [divisionId] 
     * @param {number} [snapshotId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listPersons(divisionId?: number, snapshotId?: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listPersons(divisionId, snapshotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FiltersDto} filtersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listPersonsByYearAndDivision(filtersDto: FiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listPersonsByYearAndDivision(filtersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} personId 
     * @param {boolean} showIgnored 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listProjectForPerson(personId: number, showIgnored: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listProjectForPerson(personId, showIgnored, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectFiltersDto} projectFiltersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listProjectPIByYearAndDivision(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listProjectPIByYearAndDivision(projectFiltersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectFiltersDto} projectFiltersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listProjects(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listProjects(projectFiltersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} page 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listPublications(id: number, page: number, pageSize?: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listPublications(id, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FiltersDto} filtersDto 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listPublicationsByYearAndDivision(filtersDto: FiltersDto, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listPublicationsByYearAndDivision(filtersDto, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listPublicationsMultiDivision(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listPublicationsMultiDivision(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} full 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listRemovedEntries(id: number, full: boolean, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).listRemovedEntries(id, full, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public loadCSV(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).loadCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [snapshotId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public personIndexStatus(snapshotId?: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).personIndexStatus(snapshotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fullProjectNum 
     * @param {number} year 
     * @param {string} subprojectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public projectDetails(fullProjectNum: string, year: number, subprojectId: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).projectDetails(fullProjectNum, year, subprojectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} personId 
     * @param {string} fullProjectNum 
     * @param {number} year 
     * @param {string} subprojectId 
     * @param {number} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public projectStatus(personId: number, fullProjectNum: string, year: number, subprojectId: string, status: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).projectStatus(personId, fullProjectNum, year, subprojectId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectFiltersDto} projectFiltersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public projectsBudgetYear(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).projectsBudgetYear(projectFiltersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectFiltersDto} projectFiltersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public projectsDistributionYear(projectFiltersDto: ProjectFiltersDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).projectsDistributionYear(projectFiltersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public reindexAll(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).reindexAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public reindexPerson(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).reindexPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public removeEntries(id: number, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).removeEntries(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public removeFromManual(id: number, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).removeFromManual(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public removeSnapshot(id: number, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).removeSnapshot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DivisionDto} divisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public updateDivision(id: number, divisionDto: DivisionDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).updateDivision(id, divisionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PersonDto} personDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public updatePerson(id: number, personDto: PersonDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).updatePerson(id, personDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inst 
     * @param {SettingsIntDto} settingsIntDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public updateSettings(inst: string, settingsIntDto: SettingsIntDto, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).updateSettings(inst, settingsIntDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public uploadCSV(file: File, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).uploadCSV(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public verifyList(id: number, body: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).verifyList(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


