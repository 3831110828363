/* eslint-disable jsx-a11y/anchor-is-valid */
import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {MouseEvent, useEffect, useState} from "react";
import './Charts.scss'
import {Segmented, Spin} from "antd";
import {SegmentedValue} from "antd/es/segmented";
import Column from "antd/es/table/Column";
import {numberSorter} from "../utils/utils";
import {FiltersDto, StatsProjectionWithPositions} from "../generated/api";
import {stopPropagationUtil} from "../utils/clicks";
import {FiltersUpdateContext} from "./FiltersComponent";
import {useApi} from "./ApiProvider";
import {TableWithCopy} from "./TableWithCopy";

interface ChartYearsProps {
    filters: FiltersDto
    refreshKey: number
}

function Label(p: any) {
    return <text x={Number(p.x??0)+Number(p.width??0)/2} y={Number(p.y??0)-5} textAnchor="middle" fontSize={10}>{p.value?p.value:""}</text>
}

export function ChartYears(props: ChartYearsProps) {
    const [chartOrTable, setChartOrTable] = useState<SegmentedValue>('Chart')
    const [refreshBarChart, setRefreshBarChart] = useState(0)
    const [animationActive, setAnimationActive] = useState(true)
    const api=useApi()
    const [data, setData] = useState<StatsProjectionWithPositions[]>()
    const [filters, setFilters] = useState(props.filters)

    function isSelectedYear(year: number) {
        if (filters.startYear) {
            if (filters.endYear) {
                return (year>=filters.startYear && year<=filters.endYear)
            }
            return year===filters.startYear
        }
        return false
    }

    useEffect(()=>{
        const listener = (e: Event)=>{
            setFilters((e as CustomEvent).detail)
        }
        document.addEventListener('filtersUpdated', listener)
        return ()=>{
            document.removeEventListener('filtersUpdated', listener)
        }
    }, [])

    useEffect(()=>{
        api.distributionYear(filters).then(response=>{
            setData(response.data)
        });
    },[props.refreshKey, /*filters.startYear, filters.endYear,*/ filters.minCitations, filters.minNp, filters.minRcr, filters.paper, filters.journal, filters.division, filters.position, filters.rank, filters.hypothesis, filters.types, filters.researchArticle])

    function renderBar(barProps: any) {
        if (!barProps.className) barProps.className=""
        barProps.className+=" year-chart-bar "

        if (isSelectedYear(barProps.id)) {
            barProps.className+=" selected"
        }
        return Bar.renderRectangle(true, barProps);
    }

    function reorder(data: StatsProjectionWithPositions[] | undefined) {
        if (data) {
            let newData = [...data];
            newData.sort((a,b)=>b.id-a.id);
            return newData
        }
        return undefined;
    }

    function enrich(data: StatsProjectionWithPositions[] | undefined) {
        if (data) {
            return data.map(x=>({
                ...x,
                other: x.count-x.first-x.second-x.last
            }))
        }
        return undefined;
    }

    return <FiltersUpdateContext.Consumer>
        {context=><div style={{display: "flex", flexDirection: "column", flex: 1}}>
            {data && <>
                <div className={"chart-title"}>By Year <Segmented options={['Chart', 'Table']}
                                                                  onChange={value => setChartOrTable(value)}
                                                                  style={{marginLeft: 10}}/>
                </div>
                <div style={{flex: 1, display: "flex"}}>
                    {chartOrTable==='Chart' &&
                    <ResponsiveContainer width="100%" height="100%" className={"absolute-pos"} onResize={()=> {
                        setAnimationActive(false);
                        setTimeout(()=>{
                            setAnimationActive(true)
                            setRefreshBarChart(x => x + 1)
                        }, 1000);
                    }}>
                        <BarChart data={enrich(data)} onClick={()=>context.removeFilter?.('year')} margin={{top: 15}} key={refreshBarChart}>
                            <XAxis dataKey="id" tickLine={true} fontSize={10} tickSize={3} />
                            <YAxis allowDecimals={false} />
                            {/*<Bar dataKey="first" shape={renderBar} onClick={onBarClick} stackId="stack" className={"first"} />*/}
                            {/*<Bar dataKey="second" shape={renderBar} onClick={onBarClick} stackId="stack" className={"second"} />*/}
                            {/*<Bar dataKey="last" shape={renderBar} onClick={onBarClick} stackId="stack" className={"last"} />*/}
                            {/*<Bar dataKey="other" shape={renderBar} onClick={onBarClick} stackId="stack" className={"other"}>*/}
                            {/*    <LabelList dataKey="count" position="top" content={Label} />*/}
                            {/*</Bar>*/}
                            <Bar dataKey="count" shape={renderBar} onClick={(data: StatsProjectionWithPositions, index: number, e: MouseEvent<SVGPathElement>)=>{
                                stopPropagationUtil(e)
                                context.addFilter?.(['year:' + data.id])
                            }} stackId="stack" isAnimationActive={animationActive}>
                                <LabelList dataKey="count" position="top" content={Label} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>}
                    {chartOrTable==='Table' &&
                        <TableWithCopy dataSource={reorder(data)} size={"small"} pagination={false} scroll={{y: '100%'}} className={"division-publications-table"} rowKey={'id'} >
                            <Column title={"Year"} dataIndex={'id'} sorter={numberSorter('id')}/>
                            <Column title={"Total"} dataIndex={'count'} sorter={numberSorter('count')} render={(value, record)=>
                                <a onClick={()=>context.addFilter?.(['year:' + record.id])}>{value}</a>
                            }/>
                            <Column title={"1st"} dataIndex={'first'} sorter={numberSorter('first')}
                                    render={(value, record)=><a onClick={()=>context.addFilter?.(['year:' + record.id, 'position:1st'])}>{value}</a>}
                            />
                            <Column title={"2nd"} dataIndex={'second'} sorter={numberSorter('second')}
                                    render={(value, record)=><a onClick={()=>context.addFilter?.(['year:' + record.id, 'position:2nd'])}>{value}</a>}
                            />
                            <Column title={"Last"} dataIndex={'last'} sorter={numberSorter('last')}
                                    render={(value, record)=><a onClick={()=>context.addFilter?.(['year:' + record.id, 'position:last'])}>{value}</a>}
                            />
                        </TableWithCopy>}
                </div>
            </>}
            {!data && <Spin/>}
        </div>}
    </FiltersUpdateContext.Consumer>
}